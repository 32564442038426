import React, { FC, useEffect, useState } from 'react';
import { Column, Container, Grid } from '@goldbely/explore-component-library';
import cls from 'classnames';

import Logo from './atoms/Logo';
import MobileMenuToggle from './atoms/MobileMenuToggle';
import Links from './molecules/Links';
import SearchAndCart from './molecules/SearchAndCart';
import { IPrizeMerchant } from './types';

import styles from './index.module.sass';

interface HeaderProps {
  merchant: IPrizeMerchant;
}

const Header: FC<HeaderProps> = ({ merchant: { domain, logoImageUrl, name, webLinks }}) => {
  const [ overlayLinks, setOverlayLinks ] = useState( false );
  const [ overlaySearch, setOverlaySearch ] = useState( false );

  const handleClickMenuToggle = () => {
    setOverlaySearch( false );
    setOverlayLinks(( state ) => !state );
  };

  const handleClickSearchToggle = () => {
    setOverlayLinks( false );
    setOverlaySearch(( state ) => !state );
  };

  useEffect(() => {
    const handleKeyDown = ( event: KeyboardEvent ) => {
      if ( event.key === 'Escape' ) {
        setOverlayLinks( false );
        setOverlaySearch( false );
      }
    };
    document.addEventListener( 'keydown', handleKeyDown );
    return () => {
      document.removeEventListener( 'keydown', handleKeyDown );
    };
  }, []);

  return (
    <div className={cls( styles.wrap, 'spec__wl-header' )}>
      <Container className={styles.container}>
        <Grid>
          <Column className={styles.content}>
            <div className={`${styles.mobile} ${styles.edge}`}>
              <MobileMenuToggle isActive={overlayLinks} onClick={handleClickMenuToggle} />
            </div>

            <Logo image={logoImageUrl} link={domain} name={name} />

            <Links data={webLinks} overlay={overlayLinks} />

            <div className={styles.edge}>
              <SearchAndCart
                overlaySearch={overlaySearch}
                onClickSearchToggle={handleClickSearchToggle}
              />
            </div>
          </Column>
        </Grid>
      </Container>
    </div>
  );
};

export default Header;
