import * as React from 'react';

import SvgIcon from 'src/explore/elements/SvgIcon';

export const Delayed = () => (
  <div className="d-flex justify-content-center my-7 text-primary fs-4 spec__delay">
    <SvgIcon icon="tag" svgClasses="mr-2 fs-5" />
    <span>Product is delayed</span>
  </div>
);
