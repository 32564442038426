import * as React from 'react';
import cls from 'classnames';
import { kebabCase } from 'lodash';

import styles from './index.module.sass';

export interface IPseudoOption {
  gbFormFieldLabel: string;
}

interface Props {
  availableOptions: string[];
  defaultPseudoOption: IPseudoOption;
  disabled: boolean;
  label: string;
  pseudoOptions: IPseudoOption[];
  onChange: ( selection: string ) => void;
}

export const PseudoOption: React.FC<Props> = ({
  availableOptions,
  defaultPseudoOption,
  label,
  pseudoOptions,
  onChange,
}) => {
  const [ currentSelection, setCurrentSelection ] =
    React.useState<IPseudoOption>( defaultPseudoOption );

  const handleButtonClick = ( event: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
    const target = event.target as HTMLButtonElement;
    setCurrentSelection(
      pseudoOptions.find(
        ( pseudoOption: IPseudoOption ) => pseudoOption.gbFormFieldLabel === target.innerText
      )
    );
    onChange( target.innerText );
  };

  return (
    <div className="col" key={label}>
      <div className={`${styles.label} p-0 mb-2 font-weight-normal fs-4 fs-md-5`}>{label}</div>

      <div className="d-flex flex-wrap mr-n2">
        {pseudoOptions.map(( pseudoOption ) => {
          const isSelected = pseudoOption.gbFormFieldLabel === currentSelection.gbFormFieldLabel;
          const isToBeDisabled = !availableOptions.includes( pseudoOption.gbFormFieldLabel );

          return (
            <div className="col-6 pr-3 pb-3 pl-0 d-flex" key={pseudoOption.gbFormFieldLabel}>
              <button
                type="button"
                disabled={isToBeDisabled}
                aria-pressed={isSelected}
                className={cls(
                  styles.button,
                  `btn btn-block d-flex align-items-center justify-content-center p-3 fs-4 position-relative pseudo-option-button spec__pseudo-option-${kebabCase(
                    pseudoOption.gbFormFieldLabel
                  )}`,
                  {
                    selected: isSelected,
                    [styles.on]: isSelected,
                    [styles.disabled]: isToBeDisabled,
                  }
                )}
                onClick={handleButtonClick}
              >
                {pseudoOption.gbFormFieldLabel}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
