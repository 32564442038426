import React from 'react';

const Breadcrumbs = ({ children, className = '' }) => (
  <nav className={`breadcrumbs ${className}`} role="navigation" aria-label="Breadcrumb">
    <ol
      className="breadcrumbs__list spec__breadcrumbs"
      itemScope
      itemType="http://schema.org/BreadcrumbList"
    >
      {children}
    </ol>
  </nav>
);

export default Breadcrumbs;
