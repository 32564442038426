import React, { useState } from 'react';
import cls from 'classnames';

import { Routes } from 'src/explore/services/Routes';
import { LoadingButton } from 'src/shoppe/elements/LoadingButton';
import { SvgUse } from 'src/shoppe/elements/SvgUse';
import { SendAsEgiftModal } from 'src/shoppe/elements/SendAsEgiftModal';

import styles from './index.module.sass';

type SendAsEGiftProps = {
  canAddToCart: boolean;
  isEgiftable?: boolean;
  optionSoldOut: boolean;
  productId: number;
  invalidProductOption: boolean;
};

export const SendAsEGift = ({
  canAddToCart,
  isEgiftable = false,
  optionSoldOut,
  invalidProductOption,
}: SendAsEGiftProps ) => {
  const [ open, setOpen ] = useState( false );

  const handleOpen = ( event: React.FormEvent ) => {
    event.preventDefault();
    setOpen( true );
  };

  const handleClose = () => {
    setOpen( false );
  };

  if ( !canAddToCart || !isEgiftable ) return null;

  const optionUnavailable = invalidProductOption || optionSoldOut;

  return (
    <div className={cls( styles.sendAsEgiftContainer, 'mt-4 p-4 spec__send-as-egift-container' )}>
      <LoadingButton
        className={cls(
          styles.button,
          'btn btn-secondary my-auto font-weight-bold w-100 d-flex align-items-center justify-content-center spec__send-as-egift'
        )}
        type="submit"
        onClick={handleOpen}
        disabled={optionUnavailable}
      >
        <SvgUse className="flex-shrink-0" ariaHidden="true" name="gifts" height={24} width={30} />
        <div>{optionUnavailable ? 'Unavailable' : 'Send as E-Gift'}</div>
      </LoadingButton>
      <div className="mt-2 text-center">
        <p className="font-weight-bold mb-1">Don&apos;t worry about shipping details!</p>
        <p className="mb-0">
          Send Goldbelly to friends, colleagues, & family just using their email address!
          <a
            href={`${Routes.eGifting.landing()}?ref=send_as_egift`}
            className={cls( styles.learnMore, 'font-weight-bold d-inline pl-1' )}
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
        </p>
      </div>
      <SendAsEgiftModal open={open} handleClose={handleClose} />
    </div>
  );
};
