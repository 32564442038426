import React from 'react';

import { IWebMediaAsset } from 'src/explore/types/shoppe';
import { LazyImgix } from 'src/explore/elements/LazyImgix';

import styles from './index.module.sass';

interface ThumbnailProps {
  name?: string;
  thumbnail: IWebMediaAsset;
}

export const Thumbnail = ({ name, thumbnail }: ThumbnailProps ) => (
  <>
    <LazyImgix
      alt={thumbnail.imageAlt || name}
      className={`${styles.image} position-absolute w-100 h-100 spec__product-image spec__product-image-${thumbnail.id}`}
      imgixParams={{ ar: '1:1' }}
      sizes="11vw"
      src={thumbnail.imageUrl}
    />

    {thumbnail.videoFullUrl && <div className={`${styles.video} position-absolute`} />}
  </>
);
