import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SendAsEgiftModal } from 'src/shoppe/elements/SendAsEgiftModal';
import { IProduct } from 'src/explore/types/shoppe';

import styles from './index.module.sass';

interface SpecialDateWarningProps {
  product: IProduct;
  isWhiteLabel: boolean;
}

type ISpecialWarningDate = {
  dateString?: string;
  name?: string;
  showInProduct?: boolean;
};

export const SpecialDateWarning = ({
  product: { earliestDeliveryDate, isSubscribable, isEgiftable },
  isWhiteLabel,
}: SpecialDateWarningProps ) => {
  const [ open, setOpen ] = useState( false );
  const { dateString, name, showInProduct } = useSelector(
    ( state: { data: { specialDate: ISpecialWarningDate } }) => state.data.specialDate || {}
  );

  const handleOpen = ( event: React.FormEvent ) => {
    event.preventDefault();
    setOpen( true );
  };

  const handleKeyDown = ( event: React.KeyboardEvent<HTMLElement> ) => {
    if ( event.key === 'Enter' || event.key === ' ' ) {
      event.preventDefault();
      setOpen( true );
    }
  };

  const handleClose = () => {
    setOpen( false );
  };

  if ( isSubscribable || !earliestDeliveryDate ) return null;
  if ( !showInProduct || !dateString || !name ) return null;
  if ( earliestDeliveryDate <= dateString ) return null;

  return (
    <div className="bg-dark p-4 mt-8 rounded spec__special-date">
      <div className="text-white text-center">
        <span className="ff-futura-bold">{`Arrives after ${name}`.toUpperCase()}</span>
        {isEgiftable && !isWhiteLabel && (
          <div className="font-weight-normal my-1 fs-3">
            {'Send instantly '}
            <u
              role="button"
              className={`${styles.egift} spec__special-date-egift`}
              tabIndex={0}
              onClick={handleOpen}
              onKeyDown={handleKeyDown}
            >
              as an E-Gift
            </u>
          </div>
        )}
      </div>
      <SendAsEgiftModal open={open} handleClose={handleClose} />
    </div>
  );
};
