import * as React from 'react';
import cls from 'classnames';

import { IAvailableOption } from 'src/explore/types/shoppe';
import { ntc } from 'src/explore/services/currency_helper';

import { Select } from '../../../Select';

import styles from './index.module.sass';

interface Props {
  defaultValue: IAvailableOption;
  disabled: boolean;
  label: string;
  name: string;
  options: IAvailableOption[];
  selectID: string;
  onChange: ( selection: string ) => void;
}

export const DropdownOption: React.FC<Props> = ({
  defaultValue,
  disabled,
  label,
  name,
  options,
  selectID,
  onChange,
}) => {
  const handleChange = ( event: React.ChangeEvent<HTMLSelectElement> ) => {
    onChange( event.target.value );
  };

  return (
    <div
      className={cls( 'form-group row pb-1 pb-md-0', {
        [styles.disabled]: disabled,
      })}
    >
      <div className="col">
        {label && (
          <label
            className={`${styles.label} p-0 mb-2 font-weight-normal fs-4 fs-md-5 product--product-option--label`}
            htmlFor={selectID}
          >
            {label}
          </label>
        )}

        <Select
          disabled={disabled}
          id={selectID}
          name={name}
          defaultValue={defaultValue && defaultValue.gbFormFieldValue}
          onChange={handleChange}
        >
          {options.map(( option ) => (
            <option
              key={option.gbFormFieldValue}
              disabled={!option.addToCart}
              value={option.gbFormFieldValue}
              data-price-adj={option.hasPriceAdj ? option.priceAdjInCents : undefined}
            >
              {option.gbFormFieldLabel + ( !option.addToCart ? ' (SOLD OUT)' : '' )}
              {option.hasPriceAdj &&
                ` (${option.priceAdjInCents > 0 ? '+' : ''}${ntc( option.priceAdjInCents, {
                  verbose: false,
                  precision: 2,
                })})`}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};
