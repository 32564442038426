import * as React from 'react';
import { kebabCase } from 'lodash-es';
import cls from 'classnames';
import { IAvailableOption } from 'src/explore/types/shoppe';
import { ntc } from 'src/explore/services/currency_helper';

import styles from './index.module.sass';

interface Props {
  defaultSelection?: IAvailableOption;
  label?: string;
  fieldName?: string;
  options?: IAvailableOption[];
  onChange?: ( option: string ) => void;
  showPriceAdjustments?: boolean;
  disabled?: boolean;
  className?: string;
}

export const InlineOption: React.FC<Props> = ({
  defaultSelection,
  label: groupLabel,
  fieldName,
  options,
  onChange = () => {},
  showPriceAdjustments = false,
  disabled = false,
  className,
}) => {
  const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    onChange( event.target.value );
  };

  return (
    <fieldset name={kebabCase( `option-group-${groupLabel}` )} disabled={disabled}>
      <div className={`${styles.groupTitle} p-0 mb-2 font-weight-normal fs-4 fs-md-5`}>
        {groupLabel}
      </div>
      <div className="d-flex flex-wrap mr-n2">
        {options?.map(( o ) => (
          <div
            key={`option-button-${o.gbFormFieldValue}`}
            className={cls( styles.option, 'col-6 pr-3 pb-3 pl-0 d-flex' )}
          >
            <input
              type="radio"
              id={`option-${o.gbFormFieldValue}`}
              value={o.gbFormFieldValue}
              defaultChecked={o === defaultSelection}
              name={fieldName}
              onChange={handleChange}
              disabled={!o.addToCart}
              className="sr-only"
            />
            <label
              htmlFor={`option-${o.gbFormFieldValue}`}
              className={cls(
                className,
                'btn btn-block d-flex align-items-center justify-content-center p-3 fs-4 position-relative'
              )}
            >
              <div>
                {o.gbFormFieldLabel}
                {showPriceAdjustments && o.hasPriceAdj && (
                  <div className={cls( styles.priceAdj, 'fs-3' )}>(+{ntc( o.priceAdjInCents )})</div>
                )}
              </div>
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};
