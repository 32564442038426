import React, { FC, ReactNode } from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface LinkProps {
  children?: ReactNode;
  href: string;
  visible: boolean;
}

const Link: FC<LinkProps> = ({ children, href, visible }) => (
  <a className={cls( styles.link, { [styles.visible]: visible })} href={href}>
    {children}
  </a>
);

export default Link;
