import React, { useEffect } from 'react';
import cls from 'classnames';

import { getJWPlayer } from 'src/shoppe/services/JWPlayer';
import { MediaShowcaseControls } from 'src/shoppe/elements/MediaShowcaseControls';

import { Items, IItem } from './elements/Items';

import styles from './index.module.sass';

export interface MediaShowcaseProps {
  className?: string;
  children?: React.ReactNode;
  focus: number;
  media: IItem[];
  name: string;
  previousFocus: number;
  showSelect?: boolean;
  stepBackground?: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onSelect?: ({ group, selected }: { group: number; selected: number }) => void;
}

export const MediaShowcase = ({
  className,
  children,
  focus,
  media,
  name,
  previousFocus,
  showSelect,
  stepBackground,
  onNext,
  onPrevious,
  onSelect,
}: MediaShowcaseProps ) => {
  const handleClickFixed = () => {
    if ( !media[focus]?.videoProps?.controls ) {
      const player = getJWPlayer( `media-showcase-player-${focus}` );

      if (
        player?.getState() === 'complete' ||
        player?.getState() === 'idle' ||
        player?.getState() === 'paused'
      ) {
        player?.play();
      } else {
        player?.pause();
      }
    }
  };

  // whenever a new asset is in focus, autoplay that video and reset the previous
  useEffect(() => {
    getJWPlayer( `media-showcase-player-${focus}` )?.play();
    getJWPlayer( `media-showcase-player-${previousFocus}` )?.pause();
    getJWPlayer( `media-showcase-player-${previousFocus}` )?.seek( 0 );
  }, [ focus, previousFocus ]);

  return (
    <div className={cls( styles.container, 'position-absolute spec__media-showcase', className )}>
      <MediaShowcaseControls
        focus={focus}
        media={media}
        showSelect={showSelect}
        stepBackground={stepBackground}
        onClickFixed={handleClickFixed}
        onNext={onNext}
        onPrevious={onPrevious}
        onSelect={onSelect}
      >
        <Items
          focus={focus}
          media={media}
          name={name}
          previousFocus={previousFocus}
          stepBackground={stepBackground}
        />
        {children}
      </MediaShowcaseControls>
    </div>
  );
};

export * from './hooks/useMediaShowcaseHandlers';
