import React, { FC } from 'react';
import { Column, Grid } from '@goldbely/explore-component-library';

import { Routes } from 'src/explore/services/Routes';

import { Product } from './elements/Product';

import styles from './index.module.sass';

type IProductProps = {
  hideProduct?: 'the-classics-kit' | 'large-hosting-kit' | 'limited-edition-azalea-kit';
  hideDescriptions?: boolean;
};

export const Products: FC<IProductProps> = ({ hideDescriptions = false, hideProduct }) => {
  const span = hideProduct ? { lg: 3 } : { lg: 4 }
  return (
    <Grid className={styles.grid}>
      {hideProduct !== 'large-hosting-kit' && (
        <Column span={span}>
          <Product 
            title="Large Hosting Kit"
            description="Recreate the patron experience as you celebrate the 2025 Masters with our signature pimento cheese, egg salad, pork bar-b-que and classic concessions."
            src={Routes.image.uploads( 'showcase_media_asset/image/237960/TOTM_1x1_8.jpg' )}
            href="/large-hosting-kit"
            hideDescription={hideDescriptions}
            soldOut
          />
        </Column>
      )}
      {hideProduct !== 'the-classics-kit' && (
        <Column span={span}>
          <Product 
            title="The Classics Kit"
            description="Perfect for smaller groups who want to enjoy an iconic Masters meal with our signature pimento cheese and classic concessions."
            src={Routes.image.uploads( 'showcase_media_asset/image/237203/Classics_Product_page_1.jpg' )}
            href="/the-classics-kit"
            hideDescription={hideDescriptions}
            soldOut
          />
        </Column>
      )}
      {hideProduct !== 'limited-edition-azalea-kit' && (
        <Column span={span}>
          <Product 
            title="Limited Edition Azalea Kit"
            description="Exclusive to Taste of the Masters, enjoy our new non-alcoholic Azalea cocktail mix with cups and tools to mix up the signature cocktail of the Tournament."
            src={Routes.image.uploads( 'showcase_media_asset/image/237193/Azalea_product_page_1.jpg' )}
            href="/limited-edition-azalea-kit"
            hideDescription={hideDescriptions}
            soldOut
          />
        </Column>
      )}
    </Grid>
  );
}
