import * as React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface IDatepickerNoteProps {
  choices?: string[];
}

export const DatepickerNote = ({ choices = []}: IDatepickerNoteProps ) => (
  <div className="text-center pt-5 px-4 pb-4 border-bottom">
    <h3 className="fs-6 text-uppercase mx-8 next-possible-dates">
      {choices.length > 0 ? 'Possible Delivery Dates For:' : 'Next Possible Delivery Dates*'}
    </h3>
    {choices.length > 0 ? (
      <>
        <div className="mt-2 mb-4">
          {choices.map(( c ) => (
            <div
              className={cls(
                styles.choice,
                'd-inline-block border border-dark text-dark mx-1 py-1 px-2'
              )}
            >
              {c}
            </div>
          ))}
        </div>
        <p className={cls( styles.text, 'mb-0 fs-4' )}>
          (Make different selections to see more possible dates &amp; add to cart to choose your
          delivery date at checkout.)
        </p>
      </>
    ) : (
      <>
        <p className={`${styles.text} mb-0 fs-4`}>
          This calendar is for reference only. Please select delivery date at check out.
        </p>
        <span className={`${styles.muted} fs-3`}>
          *-Adding more products to your cart can sometimes change your delivery date options at
          checkout
        </span>
      </>
    )}
  </div>
);
