import React, { FC } from 'react';
import cls from 'classnames';

import CartToggle from './atoms/CartToggle';
import SearchInput from './atoms/SearchInput';
import SearchToggle from './atoms/SearchToggle';

import styles from './index.module.sass';

interface SearchAndCartProps {
  overlaySearch: boolean;
  onClickSearchToggle: () => void;
}

const SearchAndCart: FC<SearchAndCartProps> = ({ overlaySearch, onClickSearchToggle }) => (
  <div className={styles.container}>
    <SearchToggle isActive={overlaySearch} onClick={onClickSearchToggle} />

    <div className={cls( styles.search, { [styles.overlay]: overlaySearch })}>
      <SearchInput visible={overlaySearch} />
    </div>

    <CartToggle />
  </div>
);

export default SearchAndCart;
