import React from 'react';
import cls from 'classnames';

import { MediaItem, IVideoProps } from 'src/shoppe/elements/MediaItem';
import { IWebMediaAsset } from 'src/explore/types/shoppe';

import styles from './index.module.sass';

interface ItemsProps {
  focus: number;
  media: Array<IItem>;
  name: string;
  previousFocus: number;
  stepBackground?: boolean;
}

export type IItem = IWebMediaAsset & { videoProps?: IVideoProps };

export const Items = ({ focus, media, previousFocus, stepBackground }: ItemsProps ) => (
  <ul className={`${styles.container} position-absolute overflow-hidden`}>
    {media.map(( item, i ) => (
      <li
        className={cls(
          styles.item,
          'bg-white d-flex align-items-center justify-content-center position-absolute ',
          {
            [styles.focus]: focus === i,
            [styles.previous]: previousFocus === i,
          }
        )}
        key={item.id}
      >
        <MediaItem
          className={cls( `spec__media-item-${item.id}`, {
            [styles.withStepSpace]: stepBackground,
          })}
          playerId={`media-showcase-player-${i}`}
          imageUrl={item.imageUrl}
          imageAlt={item.imageAlt}
          videoFullUrl={item.videoFullUrl}
          videoProps={{
            controls: false,
            repeat: false,
            stretching: 'fill',
            ...( item.videoProps || {}),
          }}
        />
      </li>
    ))}
  </ul>
);
