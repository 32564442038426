import React from 'react';

const Footer = () => {
  if ( !window.isLoggedIn ) return null;

  return (
    <a href="/sign-out" data-turbolinks="false">
      Sign Out
    </a>
  );
};

export default Footer;
