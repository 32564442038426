import React from 'react';
import cls from 'classnames';

import { JWPlayer } from 'src/shoppe/elements/JWPlayer';

export const EmbeddedVideo = ({
  className = '',
  jWPlayerClass = '',
  isMuted = undefined,
  videoId,
  videoType,
}) => {
  let src = `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0`;

  if ( videoType === 'youtube_playlist' ) {
    src = `https://www.youtube.com/embed/videoseries?list=${videoId}&rel=0&showinfo=0`;
  } else if ( videoType === 'vimeo' ) {
    src = `//player.vimeo.com/video/${videoId}?title=0&amp;byline=0&amp;portrait=0&amp;color=ff4d4f`;
  } else if ( videoType === 'jwplayer' ) {
    src = `https://cdn.jwplayer.com/manifests/${videoId}.m3u8`;
  }

  const iframe = (
    <div className={cls( 'embed-responsive embed-responsive-16by9 spec__embedded_video', className )}>
      <iframe
        allowFullScreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        className="embed-responsive-item"
        frameBorder="0"
        src={src}
        title="Video"
      />
    </div>
  );

  const jwplayer = (
    <div className={cls( 'spec__jwplayer_video', className )}>
      <JWPlayer
        className={jWPlayerClass}
        isAutoPlay
        isMuted={isMuted}
        file={src}
        playerId="jwplayer-video"
      />
    </div>
  );

  return videoType === 'jwplayer' ? jwplayer : iframe;
};
