import React, { FC } from 'react';

import { EmbeddedVideo } from 'src/prize/elements/EmbeddedVideo';

import styles from './index.module.sass';

export const Video: FC = () => (
  <div className={styles.container}>
    <h1 className={styles.title}>The History of Masters Concessions</h1>
    <div>
      <EmbeddedVideo videoId="aNdKcI9s" videoType="jwplayer" className={styles.video} />
      <div className={styles.info}>
        <div className={styles.now}>
          <svg className={styles.play} xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
            <path d="M2.28125 1.22055C1.81875 0.936177 1.2375 0.926802 0.765625 1.19243C0.29375 1.45805 0 1.95805 0 2.5018V13.5018C0 14.0456 0.29375 14.5456 0.765625 14.8112C1.2375 15.0768 1.81875 15.0643 2.28125 14.7831L11.2812 9.28305C11.7281 9.01118 12 8.5268 12 8.0018C12 7.4768 11.7281 6.99555 11.2812 6.72055L2.28125 1.22055Z" fill="#006747" />
          </svg>
          <div>Now Playing</div>
        </div>
        <h3 className={styles.concessions}>Concessions | Hallowed Grounds: The Story of Augusta National</h3>
      </div>
    </div>
  </div>
);
