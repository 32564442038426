import React from 'react';
import { Link } from '@reach/router';

import SvgIcon from 'src/explore/elements/SvgIcon';

const Section = ({ children }) => (
  <li
    className="breadcrumbs__li"
    itemScope
    itemProp="itemListElement"
    itemType="http://schema.org/ListItem"
  >
    {children}
  </li>
);

const Page = ({ children }) => (
  <li className="breadcrumbs__li" aria-current="page">
    {children}
  </li>
);

const Breadcrumbs = ({
  position, spa = false, textClassName = '', title, url = null,
}) => {
  const Element = url ? Section : Page;

  return (
    <Element>
      {url ? (
        <>
          <meta itemProp="position" content={position} />

          {spa ? (
            <Link to={url} className="breadcrumbs__a" itemProp="item">
              <span className={`${textClassName} text-capitalize`} itemProp="name">
                {title}
              </span>
            </Link>
          ) : (
            <a href={url} className="breadcrumbs__a" itemProp="item">
              <span className={`${textClassName} text-capitalize`} itemProp="name">
                {title}
              </span>
            </a>
          )}

          <SvgIcon icon="icon-arrow-right" svgClasses="breadcrumbs__icon" />
        </>
      ) : (
        <span className={`${textClassName} text-capitalize`}>{title}</span>
      )}
    </Element>
  );
};

export default Breadcrumbs;
