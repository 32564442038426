import React from 'react';
import cls from 'classnames';

import { IWebMediaAsset } from 'src/explore/types/shoppe';

import { Thumbnail } from './elements/Thumbnail';

import styles from './index.module.sass';

interface BatchProps {
  focus: number;
  list: number;
  media: IWebMediaAsset[];
  name?: string;
  thumbnailRef: React.MutableRefObject<HTMLLIElement>;
  onSelect: ({ group, selected }: { group: number; selected: number }) => void;
}

export const Batch = ({ focus, list, media, name, thumbnailRef, onSelect }: BatchProps ) => (
  <>
    {media.map(( item, i ) => (
      <li
        key={item.id}
        className={cls( styles.thumbnail, 'position-relative mb-2', {
          [styles.thumbnailOn]: focus === i,
        })}
        ref={thumbnailRef}
        onClick={() => onSelect({ group: list, selected: i })}
      >
        <Thumbnail name={name} thumbnail={item} />
      </li>
    ))}
  </>
);
