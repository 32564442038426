import React, { FC } from 'react';

import { LazyImgix } from 'src/explore/elements/LazyImgix';

import styles from './index.module.sass';

interface LogoProps {
  image?: string;
  link: string | null;
  name: string;
}

const Logo: FC<LogoProps> = ({ image, link, name }) => (
  <h1>
    {/* GBMC forces relative paths thus requires prepending '//' :( */}
    <a href={link ? `//${link}` : '/'} title={`${name} on Goldbelly`}>
      {image ? (
        <LazyImgix alt={name} className={styles.logo} src={image} width={600} />
      ) : (
        name
      )}
    </a>
  </h1>
);

export default Logo;
