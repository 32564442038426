import React from 'react';
import cls from 'classnames';

import { INextShippingOption } from 'src/explore/types/shoppe';

import { DeliveryDate } from './elements/DeliveryDate';
import { NextDeliveryDates } from './elements/NextDeliveryDates';
import { IDeliveryDates } from '../../types';

import styles from './index.module.sass';

interface ExpeditedProps {
  acceptsRequests: boolean;
  className?: {
    container?: string;
    date?: string;
  };
  maxDays: number;
  month: {
    format: ( date?: Date | number ) => string;
  };
  deliveryDates: IDeliveryDates;
  nextShippingOption: INextShippingOption;
  withMoreDates?: boolean;
  choices?: string[];
}

export const Expedited = ({
  acceptsRequests,
  className,
  maxDays,
  month,
  deliveryDates,
  nextShippingOption,
  withMoreDates,
  choices = [],
}: ExpeditedProps ) => {
  const { deadlineAt, estimatedDeliveryOn } = nextShippingOption;
  const today = Date.now();
  const deadlineDate = new Date( deadlineAt ).getTime();
  const secondsUntilDeadline = ( deadlineDate - today ) / 1000;

  let deadline: boolean | string = false;
  if ( secondsUntilDeadline > 0 && secondsUntilDeadline < 86400 ) {
    if ( secondsUntilDeadline < 120 ) {
      deadline = `${secondsUntilDeadline} seconds`;
    } else if ( secondsUntilDeadline < 3600 ) {
      deadline = `${Math.round( secondsUntilDeadline / 60 )} minutes`;
    } else if ( secondsUntilDeadline < 7200 ) {
      deadline = 'hour';
    } else {
      deadline = `${Math.round( secondsUntilDeadline / 3600 )} hours`;
    }
  }

  const anyDeliveryDates = Object.keys( deliveryDates ).length > 1;

  return (
    <>
      {acceptsRequests && withMoreDates ? (
        <div className={cls( 'py-8 border-top border-bottom', styles.container )}>
          <DeliveryDate
            className={className}
            estimatedDeliveryOn={estimatedDeliveryOn}
            month={month}
          />

          <p className={`${styles.text} product--get-it-by--description m-0 fs-5`}>
            {!!deadline && (
              <span className="deadline spec__deadline">{`if you order in the next ${deadline}${
                !anyDeliveryDates ? '.' : ''
              }`}</span>
            )}
            {!!anyDeliveryDates && (
              <span>{' or check out other possible delivery dates here.'}</span>
            )}
          </p>

          {!!anyDeliveryDates && (
            <div className="mt-4">
              <NextDeliveryDates dates={deliveryDates} maxDays={maxDays} choices={choices} />
            </div>
          )}
        </div>
      ) : (
        <div className={cls( 'py-8 border-top border-bottom', styles.container )}>
          <DeliveryDate
            className={className}
            estimatedDeliveryOn={estimatedDeliveryOn}
            month={month}
          />
        </div>
      )}

      {acceptsRequests && !withMoreDates && <p>Choose Delivery Date at Checkout</p>}
    </>
  );
};
