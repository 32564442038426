import { useState } from 'react';
import { IWebMediaAsset } from 'src/explore/types/shoppe';

export const ANIMATION_DURATION = 200;

export function useMediaShowcaseHandlers({ media }: { media: IWebMediaAsset[] }) {
  const [ animating, setAnimating ] = useState( false );
  const [ focus, setFocus ] = useState( 0 );
  const [ previousFocus, setPreviousFocus ] = useState( 0 );

  const handleNext = () => {
    if ( !animating ) {
      setAnimating( true );
      setPreviousFocus( focus );

      if ( focus + 1 === media.length ) {
        setFocus( 0 );
      } else {
        setFocus( focus + 1 );
      }

      setTimeout(() => setAnimating( false ), ANIMATION_DURATION );
    }
  };

  const handlePrevious = () => {
    if ( !animating ) {
      setAnimating( true );
      setPreviousFocus( focus );

      if ( focus - 1 < 0 ) {
        setFocus( media.length - 1 );
      } else {
        setFocus( focus - 1 );
      }

      setTimeout(() => setAnimating( false ), ANIMATION_DURATION );
    }
  };

  const handleSelect = ({ selected }: { group?: number; selected: number }) => {
    if ( !animating ) {
      setAnimating( true );
      setPreviousFocus( focus );
      setFocus( selected );

      setTimeout(() => setAnimating( false ), ANIMATION_DURATION );
    }
  };

  return {
    animating,
    focus,
    previousFocus,
    onNext: handleNext,
    onPrevious: handlePrevious,
    onSelect: handleSelect,
  };
}
