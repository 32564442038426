import React, { useMemo } from 'react';

import { useRoutes } from 'src/explore/hooks/useRoutes';

import styles from './index.module.sass';

interface SoldOutProps {
  soldOutReason: string;
}

export const SoldOut = ({ soldOutReason }: SoldOutProps ) => {
  const routes = useRoutes();

  const soldOutMessage = useMemo(() => {
    switch ( soldOutReason ) {
      case 'Sold Out':
        return (
          <span>
            <span>This Item is Currently </span>
            <span className="d-md-block">Sold Out</span>
          </span>
        );
      case 'Waitlist':
        return <span>This item will be available soon</span>;
      case 'Coming Soon':
        return <span>This item will be available soon</span>;
      case 'Seasonal':
        return <span>This item is currently out of season</span>;
      case 'Flash Sold':
        return (
          <span>
            <span>Deal is Sold Out</span>
            <span className={`${styles.visit} d-block mt-md-1 mb-md-7 fs-3 fs-md-5`}>
              {'Visit our '}
              <a href={routes.collection( 'hot-100-foods-of-summer' )}>
                100 Greatest Foods of Summer
              </a>
              {' until'}
              &nbsp;the&nbsp;next&nbsp;drop.
            </span>
          </span>
        );
      default:
        return <span>This item is currently unavailable</span>;
    }
  }, [ routes, soldOutReason ]);

  if ( !soldOutReason ) return null;

  return (
    <h3
      className={`${styles.text} mt-6 mb-5 fs-5 fs-md-8 text-uppercase text-center spec__product-sold-out`}
    >
      {soldOutMessage}
    </h3>
  );
};
