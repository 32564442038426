import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import cls from 'classnames';

import styles from './index.module.sass';

interface SearchInputProps {
  visible: boolean;
}

const SearchInput: FC<SearchInputProps> = ({ visible }) => (
  <form
    action="/search"
    className={cls( styles.form, 'spec__search-box', {
      [styles.visible]: visible,
    })}
    method="get"
  >
    <input
      aria-label="Search"
      className={`${styles.input} spec__search-input`}
      id="q"
      name="q"
      placeholder="Search a Product"
      type="text"
    />

    <button aria-label="Search" className={`${styles.button} spec__search-submit`} type="submit">
      <FontAwesomeIcon icon={faMagnifyingGlass} />
    </button>
  </form>
);

export default SearchInput;
