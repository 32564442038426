import React, { useEffect, useRef } from 'react';

import { IWebMediaAsset } from 'src/explore/types/shoppe';

import { Batch } from './elements/Batch';

import styles from './index.module.sass';

interface ThumbnailsProps {
  animationDuration: number;
  focus: number;
  media: IWebMediaAsset[];
  name?: string;
  previousFocus: number;
  scrollToList: number;
  onSelect: ({ group, selected }: { group: number; selected: number }) => void;
}

export const Thumbnails = ({
  animationDuration,
  focus,
  media,
  scrollToList,
  name,
  previousFocus,
  onSelect,
}: ThumbnailsProps ) => {
  const thumbnailContainerRef = useRef( null );
  const thumbnailRef = useRef( null );
  const easeOutCubic = ( t: number ) => ( t - 1 ) ** 3 + 1;

  useEffect(() => {
    if ( media.length > 4 ) {
      const thumbnailHeight =
        thumbnailRef.current.getBoundingClientRect().height +
        parseInt( getComputedStyle( thumbnailRef.current ).marginBottom, 10 );
      const container = thumbnailContainerRef.current;
      const listLength = thumbnailHeight * media.length;

      // always start/end in second list
      container.scrollTop = listLength + previousFocus * thumbnailHeight;
      const startScroll = container.scrollTop;
      const endScroll = listLength + focus * thumbnailHeight;

      // back/forward in list
      let scrollDistance = endScroll - startScroll;

      // cycle back to end of list
      if ( scrollToList === 0 ) {
        scrollDistance = -thumbnailHeight;
      }

      // cycle forward to earlier thumbnail in list
      if ( scrollToList === 2 ) {
        scrollDistance = listLength - previousFocus * thumbnailHeight + focus * thumbnailHeight;
      }

      // scroll
      let start: number = null;
      const animate = ( timestamp: number ) => {
        if ( !start ) {
          start = timestamp;
        }
        const progress = timestamp - start;

        // step
        if ( progress < animationDuration ) {
          container.scrollTop = Math.floor(
            easeOutCubic( progress / animationDuration ) * scrollDistance + startScroll
          );

          requestAnimationFrame( animate );
        }

        // finish
        else {
          container.scrollTop = endScroll;
        }
      };
      requestAnimationFrame( animate );
    }
  }, [ animationDuration, focus, media, previousFocus, scrollToList ]);

  return (
    <ul
      className={`${styles.thumbnailContainer} position-absolute d-none d-sm-block d-md-none d-lg-block h-100 pl-2 text-center overflow-hidden`}
      ref={thumbnailContainerRef}
    >
      {[ ...Array( media.length > 4 ? 3 : 1 ) ].map(( _, i ) => (
        <Batch
          focus={focus}
          media={media}
          key={i}
          list={i}
          name={name}
          thumbnailRef={thumbnailRef}
          onSelect={onSelect}
        />
      ))}
    </ul>
  );
};
