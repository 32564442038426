import React, { FC } from 'react';
import { Button } from '@goldbely/explore-component-library';

import { LazyImage } from 'src/explore/elements/LazyImage';

import styles from './index.module.sass';

type IProductProps = {
  comingSoon?: boolean;
  description: string;
  hideDescription?: boolean;
  href: string;
  src: string;
  soldOut?: boolean;
  title: string;
};

export const Product: FC<IProductProps> = ({
  comingSoon = false,
  description,
  hideDescription = false,
  href,
  soldOut = false,
  src,
  title,
}) => {
  const isSoldOut = soldOut || comingSoon;
  const buttonText = isSoldOut ? 'Notify Me' : 'Shop Now';
  const overlayText = comingSoon ? <>Coming<br />Soon</> : 'Sold Out';

  return (
    <a className={styles.link} href={href}>
      <div className={styles.imageContainer}>
        {isSoldOut && <div className={styles.overlay}>{overlayText}</div>}
        <LazyImage className={styles.image} src={src} />
      </div>
      <h2 className={styles.title}>{title}</h2>
      {!hideDescription && <div className={styles.description}>{description}</div>}
      <Button className={styles.button} type="button" outline>
        {buttonText}
      </Button>
    </a>
  );
};
