import React from 'react';

import { useShopConfig } from 'src/explore/hooks/useShopConfig';

import styles from './index.module.sass';

interface UnavailableProps {
  reason: string;
}

export const Unavailable = ({ reason }: UnavailableProps ) => {
  const config = useShopConfig();
  const isTheMasters = config.merchantPermalink === 'taste-of-the-masters';
  const defaultText = 'We will notify you via email when it becomes available to be purchased. Make sure to add yourself to the waitlist again if you were notified already! Things sell out quickly.';
  const theMastersText = 'Due to high demand, we are sold out of this offering for 2025. Please sign up if you would like to be notified about future offers.';
  const waitlistText = isTheMasters ? theMastersText : defaultText;

  return (
    <div
      className={`${styles.text} d-none d-sm-block px-7 px-md-0 px-xl-7 mt-4 mb-2 text-center spec__product-unavailable`}
    >
      {reason === 'Coming Soon' ? (
        <>
          {config.isWhitelabel ? (
            <p>
              This item is coming soon to doors nationwide on Goldbelly! Sign up to get notified
              when it&rsquo;s available to be purchased.
            </p>
          ) : (
            <p>This product is coming soon! Please check back later.</p>
          )}
        </>
      ) : (
        <p>{waitlistText}</p>
      )}
    </div>
  );
};
