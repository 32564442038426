import React from 'react';

import { Step } from './elements/Step';

import styles from './index.module.sass';

interface StepsProps {
  stepBackground?: boolean;
  onNext: () => void;
  onPrevious: () => void;
}

export const Steps = ({ stepBackground, onNext, onPrevious }: StepsProps ) => (
  <>
    <Step
      ariaLabel="View Previous Image"
      className={`${styles.left} pr-2`}
      icon="icon-arrow-left"
      stepBackground={stepBackground}
      onClick={onPrevious}
    />
    <Step
      ariaLabel="View Next Image"
      className={`${styles.right} pl-2`}
      icon="icon-arrow-right"
      stepBackground={stepBackground}
      onClick={onNext}
    />
  </>
);
