import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { camelCase } from 'lodash';

import Breakpoints from 'src/explore/services/breakpoints';
import {
  IAvailableOption,
  IAvailableVariance,
  ICartItem,
  IPromotionProduct,
} from 'src/explore/types/shoppe';
import { useShopConfig } from 'src/explore/hooks/useShopConfig';
import { SendAsEGift } from 'src/shoppe/elements/SendAsEGift';
import { SoldOut } from 'src/shoppe/elements/SoldOut';

import {
  AlmostSoldOut,
  BuyNowButton,
  Delayed,
  Options,
  Price,
  ProductDeliveryInfo,
  Quantity,
  StickyAddToCart,
  Submit,
  Unavailable,
} from './elements';
import { FlashSaleDetails } from './elements/FlashSaleDetails';
import { KlarnaElement } from '../KlarnaElement';
import useProductAvailability from './services/useProductAvailability';

interface Props {
  className?: string;
  cartItem: ICartItem;
  flashSale?: IPromotionProduct;
  hideDeliveryInfo?: boolean;
  isBetaTester?: boolean;
  live?: boolean;
}

export const OrderForm: React.FC<Props> = ({
  className,
  cartItem,
  flashSale,
  hideDeliveryInfo,
  isBetaTester = false,
  live,
}) => {
  const config = useShopConfig();

  const {
    almostSoldOut,
    availableVariances,
    buyNowEnabled,
    delayed,
    displayPrice,
    effectiveQuantityLabel,
    effectivePriceInCents,
    freeShipping,
    id,
    isEgiftable,
    isRecurringSubscription,
    permalink: productPermalink,
    priceInCents,
    priceSubheading,
    productType,
    displayDiscountPricing,
    soldOutReason,
    merchant: { permalink: merchantPermalink },
  } = cartItem.product;

  const { canAddToCart, loading } = useProductAvailability({ merchantPermalink, productPermalink });

  const [ quantity, setQuantity ] = useState<number>( cartItem.quantity ?? 1 );

  const options: IAvailableVariance[] = availableVariances.filter(
    ( variant ) => !variant.hasHardcodedOption && variant.availableOptions.length
  );
  const defaultChoice = ( variant: IAvailableVariance ) =>
    ( cartItem.id &&
      cartItem.customerChoices &&
      variant.availableOptions.find(
        ( availableOption ) =>
          availableOption.addToCart &&
          availableOption.value === cartItem.customerChoices[camelCase( variant.formField )]
      )) ||
    variant.availableOptions.find(
      ( availableOption ) => availableOption.addToCart && availableOption.isDefault
    ) ||
    variant.availableOptions.find(( availableOption ) => availableOption.addToCart ) ||
    variant.availableOptions[0];

  const [ choices, setChoices ] = useState<IAvailableOption[]>(
    options.map(( option: IAvailableVariance ) => defaultChoice( option ))
  );

  const [ anyInvalidProductOptions, setAnyInvalidProductOptions ] = useState<boolean>( false );

  const defaultValidity = options.reduce(
    ( acc: { [key: string]: boolean }, option: IAvailableVariance ) => {
      const key: string = option.formField;
      acc[key] = true;
      return acc;
    },
    {}
  );

  // this is just in case there are multiple split options
  // this ensures that the add to cart & egift buttons are disabled until all selected options are valid
  const [ validityOfProductOptions, setValidityOfProductOptions ] =
    useState<{ [key: string]: boolean }>( defaultValidity );

  const validateOption = ( variantFormField: string, valid: boolean = true ) => {
    const nextValidity = { ...validityOfProductOptions };
    nextValidity[variantFormField] = valid;
    setValidityOfProductOptions( nextValidity );

    if ( Object.values( nextValidity ).every(( value ) => value === true )) {
      setAnyInvalidProductOptions( false );
    } else {
      setAnyInvalidProductOptions( true );
    }
  };

  const defaultSoldOutStatus = options.reduce(
    ( acc: { [key: string]: boolean }, option: IAvailableVariance ) => {
      const key: string = option.formField;
      if ( option.splitForDisplay ) {
        // let's use the default choice's sold out status to start
        acc[key] = !defaultChoice( option ).addToCart;
      } else {
        // for non-split options, we never change the sold out value
        // so we can just keep it as "not sold out" because they won't be able to select sold out options anyway
        acc[key] = false;
      }
      return acc;
    },
    {}
  );

  // same deal as with validity, but for sold out status, where we need to handle the possibility that the first option is sold out
  const defaultAnyOptionSoldOut = Object.values( defaultSoldOutStatus ).some(
    ( status ) => status === true
  );
  const [ anyOptionSoldOut, setAnyOptionSoldOut ] = useState<boolean>( defaultAnyOptionSoldOut );

  const [ productOptionSoldOutStatus, setProductOptionSoldOutStatus ] =
    useState<{ [key: string]: boolean }>( defaultSoldOutStatus );

  const handleOptionSoldOut = ( variantFormField: string, soldOut: boolean = false ) => {
    const nextSoldOutStatus = { ...productOptionSoldOutStatus };
    nextSoldOutStatus[variantFormField] = soldOut;
    setProductOptionSoldOutStatus( nextSoldOutStatus );

    if ( Object.values( nextSoldOutStatus ).every(( value ) => value === false )) {
      setAnyOptionSoldOut( false );
    } else {
      setAnyOptionSoldOut( true );
    }
  };

  const handleChange = ( variant: number, selection: string ) => {
    setChoices(( priorChoices ) => {
      const updatedChoices = [ ...priorChoices ];
      updatedChoices[variant] = options[variant].availableOptions.find(
        ( option: IAvailableOption ) => option.gbFormFieldValue === Number( selection )
      );
      return updatedChoices;
    });
  };

  // Compute price based on customer selections
  const [ price, setPrice ] = useState<number>( effectivePriceInCents );

  useEffect(() => {
    let currentPrice = effectivePriceInCents;
    choices.forEach(( choice ) => {
      if ( choice && choice.hasPriceAdj ) {
        currentPrice += choice.priceAdjInCents;
      }
    });
    setPrice( currentPrice );
  }, [ effectivePriceInCents, choices ]);

  const isCustomQuantity = effectiveQuantityLabel !== 'Quantity:';
  // Do not disable form selection when the item is coming soon
  const isDisabled = loading || ( !canAddToCart && soldOutReason !== 'Coming Soon' );

  return (
    <form
      id="new_cart_item"
      className={cls( className, 'js__processing js__product-form spec__order-form' )}
      action="/cart_items"
      method="post"
      acceptCharset="UTF-8"
      noValidate
      data-loader-color="black"
    >
      <input type="hidden" id="cart_item_product_id" name="cart_item[product_id]" value={id} />

      <div
        className={cls(
          'form-group d-flex justify-content-between align-items-center pb-0',
          flashSale ? 'mb-1' : 'mb-2 mb-md-4',
          {
            'flex-wrap': isCustomQuantity,
          }
        )}
      >
        <Price
          displayDiscountPricing={displayDiscountPricing}
          effectivePriceInCents={price}
          flashSale={flashSale}
          freeShipping={freeShipping}
          priceInCents={priceInCents}
          priceSubheading={priceSubheading}
          saleEndsAt={cartItem.product.saleEndsAt}
        />

        <Quantity
          className={cls({
            'pl-4': !isCustomQuantity,
            'w-100': isCustomQuantity,
            'mt-4': isCustomQuantity && !priceSubheading,
            'mt-5 mt-lg-6': isCustomQuantity && priceSubheading,
          })}
          disabled={isDisabled}
          effectiveQuantityLabel={effectiveQuantityLabel}
          possibleQuantities={cartItem.possibleQuantities}
          quantity={quantity}
          handleChange={( newQuantity: number ) => setQuantity( newQuantity )}
        />
      </div>

      {flashSale && (
        <FlashSaleDetails effectivePriceInCents={effectivePriceInCents} flashSale={flashSale} />
      )}

      {almostSoldOut && canAddToCart && !loading && (
        <div className="d-flex justify-content-end mb-2 mb-md-4">
          <AlmostSoldOut />
        </div>
      )}

      {!loading && !canAddToCart && !config.isWhitelabel && !live && (
        <SoldOut soldOutReason={flashSale ? 'Flash Sold' : soldOutReason} />
      )}

      {productType !== 'gift_card' && !isRecurringSubscription && (
        <KlarnaElement
          className="pb-0 d-none d-md-block"
          type="credit-promotion-auto-size"
          purchaseAmountInCents={price}
        />
      )}

      {options.length > 0 && ( canAddToCart || soldOutReason === 'Coming Soon' ) && (
        <Options
          className="my-4"
          choices={choices}
          disabled={isDisabled}
          options={options}
          onChange={handleChange}
          onOptionSoldOut={handleOptionSoldOut}
          validateOption={validateOption}
        />
      )}

      {delayed && <Delayed />}

      {!loading ? (
        <>
          {Breakpoints.lessThan( 'sm' ) && (
            <StickyAddToCart
              id={cartItem.id}
              canAddToCart={!loading && canAddToCart}
              displayPrice={displayPrice}
              live={live}
              optionSoldOut={anyOptionSoldOut}
              productId={cartItem.productId}
              purchaseButtonText={cartItem.purchaseButtonText}
              soldOutReason={soldOutReason}
              invalidProductOption={anyInvalidProductOptions}
              isEgiftable={isEgiftable}
              isWhitelabel={config.isWhitelabel}
            />
          )}

          <Submit
            id={cartItem.id}
            canAddToCart={canAddToCart}
            displayPrice={displayPrice}
            isEgiftable={isEgiftable}
            isFlashSale={!!flashSale}
            live={live}
            optionSoldOut={anyOptionSoldOut}
            productId={cartItem.productId}
            purchaseButtonText={cartItem.purchaseButtonText}
            soldOutReason={soldOutReason}
            invalidProductOption={anyInvalidProductOptions}
          />

          {isBetaTester && buyNowEnabled && <BuyNowButton />}

          {!live && !canAddToCart && !flashSale && !loading && (
            <Unavailable reason={soldOutReason} />
          )}

          {!config.isWhitelabel && (
            <SendAsEGift
              canAddToCart={canAddToCart}
              optionSoldOut={anyOptionSoldOut}
              invalidProductOption={anyInvalidProductOptions}
              isEgiftable={isEgiftable}
              productId={cartItem.productId}
            />
          )}

          {!hideDeliveryInfo && (
            <ProductDeliveryInfo
              product={cartItem.product}
              isWhiteLabel={config.isWhitelabel}
              choices={choices}
              options={options}
              quantity={quantity}
            />
          )}
        </>
      ) : (
        <button
          className="btn btn-block btn-secondary py-2 fs-7 spec__loading"
          disabled
          type="button"
        >
          Checking Availability...
        </button>
      )}
    </form>
  );
};
