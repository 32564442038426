import React from 'react';
import { faPlaneDeparture } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DeliveryDateProps {
  className?: {
    date?: string;
  };
  estimatedDeliveryOn: string;
  month: {
    format: ( date?: Date | number ) => string;
  };
}

export const DeliveryDate = ({ className, estimatedDeliveryOn, month }: DeliveryDateProps ) => {
  const today = new Date();
  const weekday = new Intl.DateTimeFormat([], { weekday: 'long' });

  // https://stackoverflow.com/a/31732581
  const deliveryDate = new Date( estimatedDeliveryOn.replace( /-/g, '/' ));
  const dayDelivery = deliveryDate.getTime() / 86400000;
  const dayCurrent = today.getTime() / 86400000;

  return (
    <>
      <div className={className.date || 'fs-8 fs-lg-10 text-uppercase ff-futura-bold text-dark'}>
        <span className="d-block fs-5">
          <FontAwesomeIcon className="mr-2" icon={faPlaneDeparture} />
          Get it
        </span>
        <span className="d-block mt-2">
          {dayDelivery - dayCurrent > 4
            ? `${month.format( deliveryDate )} ${deliveryDate.getDate()}`
            : `${
                dayDelivery - dayCurrent <= 1 ? 'tomorrow' : `this ${weekday.format( deliveryDate )}`
              }`}
        </span>
      </div>
    </>
  );
};
