import 'src/shared/polyfills';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import camelCaseKeys from 'camelcase-keys';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { EppoRandomizationProvider } from 'src/explore/providers/EppoRandomizationProvider';
import { init as initStore } from 'src/explore/store';
import { RoutesProvider } from 'src/explore/hooks/useRoutes';

import App from 'src/explore/scenes/App';
import Backdrop from 'src/explore/scenes/Backdrop';
import Cart from 'src/explore/scenes/Cart';
import Footer from 'src/explore/scenes/Prize/Footer';
import ProductMedia from 'src/prize/scenes/ProductMedia';
import Breadcrumbs from 'src/prize/scenes/Breadcrumbs';
import Collection from 'src/prize/scenes/Collection';
import Header from 'src/prize/scenes/Header';
import LogIn from 'src/prize/scenes/LogIn';
import Product from 'src/prize/scenes/Product';
import ExpressCheckout from 'src/shoppe/scenes/ExpressCheckout';
import TheMastersLanding from 'src/prize/scenes/TheMastersLanding';

// CSS
import 'src/css/prize/index.sass';

// Lazy load stuff
const Auth = React.lazy(() => import( 'src/explore/scenes/Auth' ));
const EGiftCardForm = React.lazy(() => import( 'src/explore/scenes/EGiftCardForm' ));
const Megaphone = React.lazy(() => import( 'src/shoppe/scenes/Megaphone' ));
const RecommendedProducts = React.lazy(() => import( 'src/explore/scenes/RecommendedProducts' ));
const SkipToContent = React.lazy(() => import( 'src/prize/scenes/SkipToContent' ));
const Search = React.lazy(() => import( 'src/prize/scenes/Search' ));
const SuborderOptions = React.lazy(() => import( 'src/explore/scenes/SuborderOptions' ));

document.addEventListener( 'turbolinks:load', () => {
  const appElem = document.getElementById( 'app-root' );
  const authElem = document.getElementById( 'auth-root' );
  const breadcrumbsElem = document.getElementById( 'breadcrumbs-root' );
  const collectionElem = document.getElementById( 'collection-root' );
  const eGiftCardFormElem = document.getElementById( 'e-gift-card-form-root' );
  const expressCheckoutElem = document.getElementById( 'express-checkout-root' );
  const footerElem = document.getElementById( 'footer-root' );
  const headerElem = document.getElementById( 'header-root' );
  const logInElem = document.getElementById( 'log-in-root' );
  const logInMobileElem = document.getElementById( 'log-in-root-mobile' );
  const megaphoneElem = document.getElementById( 'megaphone-root' );
  const preCartElem = document.getElementById( 'pre-cart-root' );
  const productElem = document.getElementById( 'product-root' );
  const productMediaElem = document.getElementById( 'product-media-root' );
  const recommendedProductsElem = document.getElementById( 'recommended-products-root' );
  const skipToContentElem = document.getElementById( 'skip-to-content-root' );
  const searchElem = document.getElementById( 'search-root' );
  const suborderOptionsElems = document.getElementsByClassName( 'suborder-options-root' );
  const theMastersLandingElem = document.getElementById( 'the-masters-landing-root' );

  const store = initStore({
    config: {
      isWhitelabel: true,
    },
  });

  if ( appElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <EppoRandomizationProvider>
          <App />
        </EppoRandomizationProvider>
      </Provider>,
      appElem
    );
  }

  if ( authElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <RoutesProvider>
            <Auth refresh />
          </RoutesProvider>
        </Suspense>
      </Provider>,
      authElem
    );
  }

  if ( breadcrumbsElem ) {
    try {
      const breadcrumbs = JSON.parse( breadcrumbsElem.getAttribute( 'data-breadcrumbs' ));

      ReactDOM.render(
        <Provider store={store}>
          <Suspense fallback={null}>
            <RoutesProvider>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </RoutesProvider>
          </Suspense>
        </Provider>,
        breadcrumbsElem
      );
    } catch ( e ) {
      // bad breadcrumbs
    }
  }

  // Only render if root element is present
  if ( collectionElem ) {
    const clickRef = collectionElem.getAttribute( 'data-ref' );
    const collectionId = collectionElem.getAttribute( 'data-collection-id' );

    ReactDOM.render(
      <Provider store={store}>
        <Collection clickRef={clickRef} collectionId={collectionId} />
      </Provider>,
      collectionElem
    );
  }

  if ( eGiftCardFormElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <EGiftCardForm cartItem={camelCaseKeys( window._cart_item, { deep: true })} />
        </Suspense>
      </Provider>,
      eGiftCardFormElem
    );
  }

  if ( expressCheckoutElem ) {
    const stripePromise = loadStripe( window.stripeKey );

    ReactDOM.render(
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <ExpressCheckout />
        </Elements>
      </Provider>,
      expressCheckoutElem
    );
  }

  if ( footerElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Footer />
      </Provider>,
      footerElem
    );
  }

  if ( headerElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Header merchant={camelCaseKeys( window._merchant, { deep: true })} />
      </Provider>,
      headerElem
    );
  }

  if ( logInElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <LogIn />
      </Provider>,
      logInElem
    );
  }

  if ( logInMobileElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <LogIn isMobile />
      </Provider>,
      logInMobileElem
    );
  }

  if ( megaphoneElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <Megaphone />
        </Suspense>
      </Provider>,
      megaphoneElem
    );
  }

  if ( preCartElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <>
          <Backdrop />

          <EppoRandomizationProvider>
            <Cart mode="precart" editable />
          </EppoRandomizationProvider>
        </>
      </Provider>,
      preCartElem
    );
  }

  if ( productElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <Product cartItem={camelCaseKeys( window._cart_item, { deep: true })} />
        </Suspense>
      </Provider>,
      productElem
    );
  }

  if ( productMediaElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <ProductMedia product={camelCaseKeys( window._cart_item.product, { deep: true })} />
        </Suspense>
      </Provider>,
      productMediaElem
    );
  }

  if ( recommendedProductsElem ) {
    const clickRef = recommendedProductsElem.getAttribute( 'data-ref' );

    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <RecommendedProducts clickRef={clickRef} hideMerchant pageType="also_bought" />
        </Suspense>
      </Provider>,
      recommendedProductsElem
    );
  }

  if ( skipToContentElem ) {
    ReactDOM.render(
      <Suspense fallback={null}>
        <SkipToContent />
      </Suspense>,
      skipToContentElem
    );
  }

  if ( searchElem ) {
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={null}>
          <Search />
        </Suspense>
      </Provider>,
      searchElem
    );
  }

  if ( suborderOptionsElems ) {
    Array.from( suborderOptionsElems ).forEach(( elem ) => {
      const id = elem.getAttribute( 'data-id' );
      const orderType = elem.getAttribute( 'data-order-type' );
      const merchantNames = elem.getAttribute( 'data-merchant-names' );
      const placedByGuest = elem.hasAttribute( 'data-by-guest' );
      const giftMessageBody = elem.getAttribute( 'data-gift-message' );
      const giftMessageFrom = elem.getAttribute( 'data-gift-message-from' );
      const requestedDeliveryOn = elem.getAttribute( 'data-requested-delivery-on' );
      const shippingSubtotal = elem.getAttribute( 'data-shipping-subtotal' );
      const disableOptions = elem.hasAttribute( 'data-disable-options' );

      ReactDOM.render(
        <Provider store={store}>
          <Suspense fallback={null}>
            <SuborderOptions
              id={id}
              orderType={orderType}
              merchantNames={merchantNames}
              placedByGuest={placedByGuest}
              giftMessageBody={giftMessageBody}
              giftMessageFrom={giftMessageFrom}
              requestedDeliveryOn={requestedDeliveryOn}
              shippingSubtotal={shippingSubtotal}
              disabled={disableOptions}
            />
          </Suspense>
        </Provider>,
        elem
      );
    });

    if ( theMastersLandingElem ) {
      ReactDOM.render(
        <Provider store={store}>
          <Suspense fallback={null}>
            <TheMastersLanding />
          </Suspense>
        </Provider>,
        theMastersLandingElem
      );
    }
  }
});

document.addEventListener( 'turbolinks:before-cache', () => {
  const ids = [
    'app-root',
    'auth-root',
    'breadcrumbs-root',
    'collection-root',
    'e-gift-card-form-root',
    'express-checkout-root',
    'footer-root',
    'log-in-root',
    'log-in-root-mobile',
    'megaphone-root',
    'pre-cart-root',
    'product-root',
    'product-media-root',
    'recommended-products-root',
    'skip-to-content-root',
    'search-root',
    'the-masters-landing-root',
  ];

  let elem = null;
  ids.forEach(( id ) => {
    elem = document.getElementById( id );

    // Only unmount if root element is present
    if ( elem ) {
      ReactDOM.unmountComponentAtNode( elem );
    }
  });
});
