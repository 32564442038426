import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import cls from 'classnames';

import styles from './index.module.sass';

interface SearchToggleProps {
  isActive: boolean;
  onClick: () => void;
}

const SearchToggle: FC<SearchToggleProps> = ({ isActive, onClick }) => (
  <button
    aria-label="Search"
    className={cls( styles.button, { [styles.active]: isActive })}
    type="button"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faMagnifyingGlass} />
  </button>
);

export default SearchToggle;
