import React from 'react';
import { Helmet } from 'react-helmet';

import { useShopConfig } from 'src/explore/hooks/useShopConfig';

import { EmbeddedVideo } from 'src/prize/elements/EmbeddedVideo';
import { ProductHeaderInlineV1 } from 'src/shoppe/elements/ProductHeaderInlineV1';
import { ProductMedia } from 'src/shoppe/elements/ProductMedia';
import { OrderForm } from 'src/shoppe/elements/OrderForm';

import Breadcrumbs from 'src/prize/scenes/Breadcrumbs';

import { Products } from '../TheMastersLanding/elements/Products';

import { Details } from './elements/Details';

const Product = ({ cartItem }) => {
  const { availableForPickUp, canAddToCart, effectiveName, merchant, isNew, permalink, sku, videoId, videoPartial } =
    cartItem.product;

  const config = useShopConfig();
  const isTheMasters = config.merchantPermalink === 'taste-of-the-masters';
  const homeName = isTheMasters ? merchant.name : 'Nationwide Shipping';
  const breadcrumbs = [
    {
      name: homeName,
      url: '/',
    },
    {
      name: effectiveName,
      url: null,
    },
  ];

  return (
    <>
      <Helmet>
        <meta itemProp="identifier" content={`sku:${sku}`} />
        {canAddToCart ? (
          <link itemProp="availability" href="http://schema.org/InStock" />
        ) : (
          <link itemProp="availability" href="http://schema.org/OutOfStock" />
        )}
      </Helmet>

      <div
        className="product-container spec__product"
        itemScope
        itemType="http://data-vocabulary.org/Product"
      >
        <div className="product-row">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <div className="product__left">
            {videoId ? (
              <EmbeddedVideo jWPlayerClass="pb-2" videoId={videoId} videoType={videoPartial} />
            ) : (
              <ProductMedia isWhiteLabel={config.isWhitelabel} product={cartItem.product} />
            )}
          </div>
          <div className="product__right">
            <div className="product__panel">
              <ProductHeaderInlineV1
                isNew={isNew}
                isWhiteLabel={config.isWhitelabel}
                name={effectiveName}
              />
              <OrderForm
                cartItem={cartItem}
                className="mt-6"
                hideDeliveryInfo={!!availableForPickUp}
              />
            </div>
          </div>
          <div className="product__details">
            {cartItem.product.descriptionHtml && (
              <div
                className="product__description spec__product-description"
                itemProp="description"
                dangerouslySetInnerHTML={{ __html: cartItem.product.descriptionHtml }}
              />
            )}

            {cartItem.product.videoId && (
              <div className="my-8">
                <ProductMedia isWhiteLabel={config.isWhitelabel} product={cartItem.product} />
              </div>
            )}

            <Details product={cartItem.product} whiteLabel={config.isWhitelabel} />
          </div>
        </div>
        {isTheMasters && (
          <div className="my-8 py-8 px-4">
            <h2 className="mb-4">Recommended For You</h2>
            <Products hideProduct={permalink} hideDescriptions />
          </div>
        )}
      </div>
    </>
  );
};

export default Product;
