import React from 'react';

import { FilteredProductSearch } from 'src/explore/compositions/FilteredProductSearch';

// Order is important
const AVAILABLE_SORTING = [ 'recommended', 'best_sellers', 'price_low', 'price_high' ];

const Collection = ({ clickRef, collectionId }) => (
  <div className="container-xl spec__collection">
    <FilteredProductSearch
      availableSorting={AVAILABLE_SORTING}
      clickRef={clickRef}
      conditions={{ collection_ids: collectionId }}
      hideMerchant
    />
  </div>
);

export default Collection;
