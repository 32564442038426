import * as React from 'react';
import { useSelector } from 'react-redux';
import camelCaseKeys from 'camelcase-keys';

import { IAccountV2 } from 'src/explore/types/shoppe';
import NumberShortener from 'src/explore/services/NumberShortener';

import { AuthButtons } from './elements/AuthButtons';

import styles from './index.module.sass';

interface ILogInProps {
  isMobile?: boolean;
}

const LogIn: React.FC<ILogInProps> = ({ isMobile }) => {
  const account = useSelector<any, IAccountV2>(( state ) =>
    camelCaseKeys( state.data.account.data, { deep: true })
  );

  return (
    <>
      {!account ? (
        <AuthButtons className={styles.login} buttonClassName={styles.button} />
      ) : (
        <a className="login--auth-name" href="/rewards?ref=header">
          <div className={styles.name}>{`Hi ${account.firstName || 'Food Explorer'}`}</div>
          {account.rewardPoints > 0 && !isMobile && (
            <div className={styles.point}>
              {`(${NumberShortener.shorten( account.rewardPoints, {
                min: 100,
              })} Reward Points)`}
            </div>
          )}
        </a>
      )}
    </>
  );
};

export default LogIn;
