import * as React from 'react';
import cls from 'classnames';
import { kebabCase } from 'lodash';

import { IAvailableOption, IAvailableVariance } from 'src/explore/types/shoppe';

import styles from './index.module.sass';
import { IPseudoOption, PseudoOption } from './elements/PseudoOption';

interface Props {
  defaultOption: IAvailableOption;
  disabled: boolean;
  name: string;
  onChange: ( selection: string ) => void;
  option: IAvailableVariance;
  optionSoldOut: ( variantFormField: string, soldOut?: boolean ) => void;
  selectID: string;
  validateOption: ( variantFormField: string, valid?: boolean ) => void;
}

export const SplitOption: React.FC<Props> = ({
  defaultOption,
  disabled,
  name,
  onChange,
  option,
  optionSoldOut,
  selectID,
  validateOption,
}) => {
  const { delimiter, availableOptions } = option;

  // ex. "Size - Color" becomes [ "Size", "Color" ]
  // ex. "Size - Color - Material" becomes [ "Size", "Color", "Material" ]
  const groupLabels = option.gbLabel.split( delimiter );

  // ex. [ "Large - Red", "Large - Blue" ] becomes [ ["Large", "Red"], ["Large", "Blue"] ]
  // ex. [ "Large - Red - Cotton", "Large - Blue - Cotton" ] becomes [ ["Large", "Red", "Cotton"], ["Large", "Blue", "Cotton"] ]
  const optionLabels = availableOptions.map(( availableOption ) =>
    availableOption.gbFormFieldLabel.split( delimiter )
  );

  const splitDefaultOption = defaultOption.gbFormFieldLabel.split( delimiter );

  // ex. [ ["Large", "Red"], ["Large", "Blue"] ] becomes [ ["Large"], ["Red", "Blue"] ]
  // ex. [ ["Large", "Red", "Cotton"], ["Large", "Blue", "Cotton"] ] becomes [ ["Large"], ["Red", "Blue"], ["Cotton"] ]
  const uniqueGroupedOptionLabels: Array<string>[] = [];
  groupLabels.forEach(( _label, index ) => {
    optionLabels.forEach(( optionLabel ) => {
      if ( uniqueGroupedOptionLabels[index]) {
        if ( !uniqueGroupedOptionLabels[index].includes( optionLabel[index])) {
          uniqueGroupedOptionLabels[index].push( optionLabel[index]);
        }
      } else {
        uniqueGroupedOptionLabels[index] = [ optionLabel[index] ];
      }
    });
  });

  const groupedPseudoOptions: IPseudoOption[][] = uniqueGroupedOptionLabels.map(
    ( groupedOptionLabels ) =>
      groupedOptionLabels.map(( optionLabel ) => ({
        gbFormFieldLabel: optionLabel,
      }))
  );

  const [ originalProductOption, setOriginalProductOption ] =
    React.useState<IAvailableOption>( defaultOption );

  const findMatchingDefaultOption = ( index: number ) => {
    const defaultOptionLabel = splitDefaultOption[index];
    return groupedPseudoOptions[index].find(
      ( pseudoOption ) => pseudoOption.gbFormFieldLabel === defaultOptionLabel
    );
  };

  const defaultPseudoSelections = groupLabels.map(
    ( _label, index ) => findMatchingDefaultOption( index ).gbFormFieldLabel
  );

  const [ pseudoSelections, setPseudoSelections ] = React.useState<string[]>( defaultPseudoSelections );

  // this accounts for both sold out & invalid combinations so both states will appear disabled
  const filteredAvailableOptions = ( index: number ) => {
    // get the combo of pseudoSelections prior to the current index & concat them
    // this allows us to better handle cases where there are more than 2 pseudo options (ex. "Size - Color - Material")
    const priorSelections = pseudoSelections.slice( 0, index ).join( delimiter );
    const filteredOptions = availableOptions.filter(
      ( availableOption ) =>
        availableOption.gbFormFieldLabel.startsWith( priorSelections ) && availableOption.addToCart
    );

    return filteredOptions.map(
      ( filteredOption ) => filteredOption.gbFormFieldLabel.split( delimiter )[index]
    );
  };

  const handlePseudoChange = ( index: number, selection: string ) => {
    const currentSelections = [ ...pseudoSelections ];
    currentSelections[index] = selection;
    const combinedOptions = currentSelections.join( delimiter );

    // find the "real" option that matches the current combination of pseudo options
    const nextOption = availableOptions.find(
      ( availableOption ) => availableOption.gbFormFieldLabel === combinedOptions
    );

    if ( nextOption ) {
      // setting originalProductOption is what sets the value in the hidden select
      setOriginalProductOption( nextOption );
      validateOption( option.formField, true );

      // this is what triggers the price update
      onChange( nextOption.gbFormFieldValue.toString());

      // determines if we should display "Sold Out" on the "Add to Cart"
      if ( nextOption.addToCart ) {
        optionSoldOut( option.formField, false );
      } else {
        optionSoldOut( option.formField, true );
      }
    } else {
      // if there isn't a matching "real" option, display "Unavailable" instead of "Add to Cart"
      validateOption( option.formField, false );
    }

    setPseudoSelections( currentSelections );
  };

  return (
    <>
      {groupLabels.map(( label, index ) => (
        <div
          className={cls( `form-group row pb-1 pb-md-0 spec__pseudo-group-${kebabCase( label )}`, {
            [styles.disabled]: disabled,
          })}
          key={label}
        >
          <PseudoOption
            availableOptions={filteredAvailableOptions( index )}
            defaultPseudoOption={findMatchingDefaultOption( index )}
            disabled={disabled}
            label={label}
            pseudoOptions={groupedPseudoOptions[index]}
            onChange={( selection: string ) => handlePseudoChange( index, selection )}
          />
        </div>
      ))}

      <div
        className={cls(
          `form-group pb-1 pb-md-0 spec__hidden-dropdown-${option.formField}`,
          styles.hidden
        )}
        aria-hidden="true"
      >
        <div className="col">
          {option.gbLabel && (
            <label
              className={`${styles.label} p-0 mb-2 font-weight-normal fs-4 fs-md-5`}
              htmlFor={selectID}
            >
              {option.gbLabel}
            </label>
          )}

          <select
            disabled={disabled}
            id={selectID}
            name={name}
            value={originalProductOption.gbFormFieldValue}
            onChange={() => {}}
          >
            {availableOptions.map(( availableOption ) => (
              <option
                key={availableOption.gbFormFieldValue}
                disabled={!availableOption.addToCart}
                value={availableOption.gbFormFieldValue}
                data-price-adj={
                  availableOption.hasPriceAdj ? availableOption.priceAdjInCents : undefined
                }
              >
                {availableOption.gbFormFieldLabel}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};
