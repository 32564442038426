import React, { useEffect, useState } from 'react';
import { PillBadge } from '@goldbely/explore-component-library';

import { getSaleText } from './utils';

import styles from './index.module.sass';

type Props = {
  saleEndsAt?: string;
};

export const SalePillBadge: React.FC<Props> = ({ saleEndsAt }) => { 
  const [ saleText, setSaleText ] = useState<string>();

  const updateSaleText = () => {
    setSaleText( getSaleText( saleEndsAt ));
  };

  useEffect(() => {
    updateSaleText()

    const now = new Date();
    const saleEndsDate = new Date( saleEndsAt );
    const timeLeft = saleEndsDate.valueOf() - now.valueOf();
    const twentyFourHours = 86400000;
    if ( timeLeft > twentyFourHours ) {
      return undefined;
    }

    const timerId = setInterval( updateSaleText, 60000 );
    return () => clearInterval( timerId );
  }, [ saleEndsAt ]);

  return ( <div>{!!saleText && <PillBadge className={styles.pill}>{saleText}</PillBadge>}</div> );
}
