import React from 'react';
import cls from 'classnames';

import { SvgUse } from 'src/shoppe/elements/SvgUse';

import styles from './index.module.sass';

interface StepProps {
  ariaLabel: string;
  className: string;
  icon: string;
  stepBackground?: boolean;
  onClick: () => void;
}

export const Step = ({ ariaLabel, className, icon, stepBackground, onClick }: StepProps ) => (
  <button
    aria-label={ariaLabel}
    type="button"
    className={cls(
      styles.step,
      'position-absolute border-0',
      stepBackground
        ? `${styles.background} d-flex justify-content-center rounded-circle bg-white`
        : 'bg-transparent text-white',
      className
    )}
    onClick={onClick}
  >
    <SvgUse
      ariaHidden="true"
      className={styles.icon}
      height={stepBackground ? 35 : 38}
      name={icon}
      role="presentation"
      width={stepBackground ? 22 : 24}
    />
  </button>
);
