import React from 'react';

import { StringFormatter } from 'src/explore/services/StringFormatter';

import styles from './index.module.sass';

interface ServingSizeProps {
  displayServingLabel?: string;
  displayServingSize: string;
  displayServingUnit?: string;
}

export const ServingSize = ({
  displayServingLabel,
  displayServingSize,
  displayServingUnit,
}: ServingSizeProps ) => (
  <svg
    aria-label={StringFormatter.spaceBetween(
      displayServingLabel,
      displayServingSize,
      displayServingUnit
    )}
    className={`${styles.count} product__serving-size position-absolute w-100 text-white font-weight-bold`}
    height="60"
    preserveAspectRatio="xMinYMin meet"
    role="img"
    viewBox="0 0 268 60"
    width="268"
    xmlns="http://www.w3.org/2000/svg"
  >
    {displayServingLabel && (
      <text className={`${styles.servingLabel} fs-5 text-uppercase`} x="11" y="18">
        {displayServingLabel}
      </text>
    )}
    <text x="11" y="49">
      <tspan>{displayServingSize}</tspan>
      {displayServingUnit && <tspan className="fs-9">{` ${displayServingUnit}`}</tspan>}
    </text>
  </svg>
);
