import React, { useState } from 'react';
import { DayPickerRangeController } from 'react-dates';
import { faCalendarAlt, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import Breakpoints from 'src/explore/services/breakpoints';
import { Modal } from 'src/shoppe/elements/Modal';

import { IDeliveryDates } from '../../../../types';
import { DatepickerNote } from './elements/DatepickerNote';

import styles from './index.module.sass';

interface NextDeliveryDatesProps {
  dates: IDeliveryDates;
  maxDays: number;
  choices?: string[];
}

export const NextDeliveryDates = ({ dates, maxDays, choices }: NextDeliveryDatesProps ) => {
  const [ open, setOpen ] = useState( false );

  return (
    <>
      <button
        type="button"
        className="btn btn-block btn-outline-secondary py-3 px-7 font-weight-bold spec__view_calendar"
        onClick={( e ) => {
          e.preventDefault();
          setOpen( true );
        }}
      >
        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
        View More Dates
      </button>

      <Modal
        containerClass="p-0 border-0"
        open={open}
        onClick={() => {
          setOpen( false );
        }}
        closeBtn
      >
        <DayPickerRangeController
          calendarInfoPosition="top"
          endDate={null}
          focusedInput={null}
          hideKeyboardShortcutsPanel
          initialVisibleMonth={null}
          isOutsideRange={( day: Moment ) => {
            if ( Object.prototype.hasOwnProperty.call( dates, day.format( 'YYYY-MM-DD' ))) {
              return false;
            }
            return true;
          }}
          minDate={moment()}
          maxDate={moment().add( maxDays, 'days' )}
          numberOfMonths={Breakpoints.appliesTo( 'md' ) ? 3 : 1}
          renderCalendarInfo={() => <DatepickerNote choices={choices} />}
          renderDayContents={( day: Moment ) => (
            <div className={`${styles.day} py-1`}>
              <div className="font-weight-bold fs-3">{day.format( 'D' )}</div>
              {Object.prototype.hasOwnProperty.call( dates, day.format( 'YYYY-MM-DD' )) && (
                <>
                  {!dates[day.format( 'YYYY-MM-DD' )] ? (
                    <strong className={`${styles.sold} d-block pt-1`}>
                      Sold
                      <br />
                      Out
                    </strong>
                  ) : (
                    <FontAwesomeIcon icon={faCheckCircle} className="mt-1 text-secondary fs-5" />
                  )}
                </>
              )}
            </div>
          )}
          startDate={null}
          onDatesChange={() => {}}
          onFocusChange={() => {}}
        />
      </Modal>
    </>
  );
};
