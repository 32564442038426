import React, { useMemo } from 'react';
import { compact, flatten } from 'lodash-es';

import {
  IAvailableOption,
  IAvailableVariance,
  INextShippingOption,
} from 'src/explore/types/shoppe';
import { useApiObject } from 'src/explore/hooks/useApiObject';
import { Expedited } from './elements/Expedited';
import { IDeliveryDates } from './types';
import { makeShippingDateEndpointUrl } from '../../services/endpointHelper';

type INextDeliveryDates = {
  dates: IDeliveryDates;
};

interface ShippingProps {
  acceptsRequests: boolean;
  className?: {
    container?: string;
    date?: string;
  };
  maxDays: number;
  nextShippingOption: INextShippingOption;
  productId: number;
  withMoreDates?: boolean;
  choices?: IAvailableOption[];
  options?: IAvailableVariance[];
  quantity?: number;
}

export const Shipping = ({
  acceptsRequests,
  className,
  maxDays,
  nextShippingOption,
  productId,
  withMoreDates,
  choices = [],
  options = [],
  quantity = 1,
}: ShippingProps ) => {
  const { isGround, toShipOn } = nextShippingOption;
  const shipDate = new Date( toShipOn.replace( /-/g, '/' ));
  const month = new Intl.DateTimeFormat([], { month: 'short' });

  const deliveryDatesEndpoint = makeShippingDateEndpointUrl({
    baseUrl: `products/${String( productId )}/next_delivery_dates`,
    quantity,
    choices: choices.map(( choice: IAvailableOption ) => choice.gbFormFieldValue ),
  });
  const deliveryDates = useApiObject<INextDeliveryDates>( deliveryDatesEndpoint );

  const choicesText = useMemo(() => {
    const hasSplitOptions = options.map(( o ) => o.splitForDisplay ).includes( true );
    if ( !choices || !options || !hasSplitOptions ) return [];

    return compact(
      flatten(
        options.length > 0 &&
          choices.map(
            ( c, i ) =>
              options[i] &&
              options[i].splitForDisplay &&
              c.gbFormFieldLabel.split( options[i].delimiter )
          )
      )
    );
  }, [ choices, options ]);

  return (
    <>
      {isGround ? (
        <span>
          {`Will ship on ${month.format(
            shipDate
          )} ${shipDate.getDate()} and arrive within 3-5 business days.`}
        </span>
      ) : (
        <Expedited
          acceptsRequests={acceptsRequests}
          className={className}
          maxDays={maxDays}
          month={month}
          choices={choicesText}
          deliveryDates={deliveryDates.data ? deliveryDates.data.dates : {}}
          nextShippingOption={nextShippingOption}
          withMoreDates={withMoreDates}
        />
      )}
    </>
  );
};
