import React from 'react';
import cls from 'classnames';

import { IAvailableOption, IAvailableVariance } from 'src/explore/types/shoppe';

import { SplitOption } from './elements/SplitOption';
import { DropdownOption } from './elements/DropdownOption';
import { InlineOption } from './elements/InlineOption';

interface Props {
  className?: string;
  choices: IAvailableOption[];
  disabled: boolean;
  options: IAvailableVariance[];
  onChange: ( option: number, selection: string ) => void;
  onOptionSoldOut: ( variantFormField: string, soldOut?: boolean ) => void;
  validateOption: ( variantFormField: string, valid?: boolean ) => void;
}

export const Options: React.FC<Props> = ({
  className,
  choices,
  disabled,
  options,
  onChange,
  onOptionSoldOut,
  validateOption,
}) => (
  <div className={cls( 'spec__product-options', className )}>
    {options.map(( option: IAvailableVariance, i: number ) => {
      const formFieldName = `cart_item[product_option_ids][${option.formField}]`;

      return (
        <div className="product__available-variances" key={option.formField}>
          {option.splitForDisplay ? (
            <SplitOption
              defaultOption={choices[i]}
              disabled={disabled}
              key={option.formField}
              name={formFieldName}
              option={option}
              selectID={option.formField}
              onChange={( selection: string ) => onChange( i, selection )}
              optionSoldOut={onOptionSoldOut}
              validateOption={validateOption}
            />
          ) : (
            <>
              {option.displayMode === 'inline' ? (
                <InlineOption
                  defaultSelection={choices[i]}
                  label={option.gbLabel}
                  options={option.availableOptions}
                  onChange={( selection: string ) => onChange( i, selection )}
                  fieldName={formFieldName}
                  showPriceAdjustments
                />
              ) : (
                <DropdownOption
                  defaultValue={choices[i]}
                  disabled={disabled}
                  label={option.gbLabel}
                  name={formFieldName}
                  options={option.availableOptions}
                  selectID={option.formField}
                  onChange={( selection: string ) => onChange( i, selection )}
                />
              )}
            </>
          )}
        </div>
      );
    })}
  </div>
);
