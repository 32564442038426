import camelcaseKeys from 'camelcase-keys';
import { useApiObject } from 'src/explore/hooks/useApiObject';

interface IProps {
  merchantPermalink: string;
  productPermalink: string;
}

type TData = {
  canAddToCart: boolean;
};

const useProductAvailability = ({ merchantPermalink, productPermalink }: IProps ) => {
  const { data, loading, error } = useApiObject<TData>(
    `/merchants/${merchantPermalink}/products/${productPermalink}/availability`,
    {
      apiPathOverride: '/api/v3',
    }
  );

  return {
    ...( error ? { canAddToCart: true } : camelcaseKeys( data )),
    loading,
  };
};

export default useProductAvailability;
