import React from 'react';

import { CarouselIndicators } from 'src/shoppe/elements/CarouselIndicators';
import { IWebMediaAsset } from 'src/explore/types/shoppe';

import { Fixed } from './elements/Fixed';
import { Steps } from './elements/Steps';

import styles from './index.module.sass';

interface MediaShowcaseControlsProps {
  children?: React.ReactNode;
  focus: number;
  media: IWebMediaAsset[];
  showSelect?: boolean;
  stepBackground?: boolean;
  onClickFixed?: () => void;
  onNext: () => void;
  onPrevious: () => void;
  onSelect?: ({ group, selected }: { group: number; selected: number }) => void;
}

export const MediaShowcaseControls = ({
  children,
  focus,
  media,
  showSelect,
  stepBackground,
  onClickFixed,
  onNext,
  onPrevious,
  onSelect,
}: MediaShowcaseControlsProps ) => (
  <>
    <Fixed onClick={onClickFixed} onSwipeLeft={onNext} onSwipeRight={onPrevious}>
      {children}
    </Fixed>

    {media.length > 1 && (
      <>
        <Steps stepBackground={stepBackground} onNext={onNext} onPrevious={onPrevious} />

        {showSelect && (
          <CarouselIndicators
            classNames={{
              container: `${styles.index} position-absolute`,
              indicator: 'border-0',
            }}
            activeIndex={focus}
            length={media.length}
            onClick={( i ) => onSelect({ group: 1, selected: i })}
          />
        )}
      </>
    )}
  </>
);
