import React from 'react';

import SvgIcon from 'src/explore/elements/SvgIcon';

interface DetailProps {
  children?: React.ReactNode;
  title: string;
}

export const Detail = ({ children, title }: DetailProps ) => (
  <div
    className={`product__info accordion-panel js__accordion-panel spec__${title
      .toLowerCase()
      .replace( /[^\w]+/g, '-' )}`}
  >
    <h3
      className="product__info-title accordion-panel__trigger js__accordion-panel-trigger"
      role="button"
      aria-pressed="false"
    >
      <div className="accordion-panel__trigger-title">{title}</div>
      <div className="accordion-panel__trigger-icon text-primary">
        <SvgIcon icon="icon-pointer-down" />
      </div>
    </h3>
    <div className="accordion-panel__content">{children}</div>
  </div>
);
