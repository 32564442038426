import React from 'react';

import { useShopConfig } from 'src/explore/hooks/useShopConfig';

import { ProductMedia as ProductMediaElement } from 'src/shoppe/elements/ProductMedia';

const ProductMedia = ({ product }) => {
  const config = useShopConfig();

  return <ProductMediaElement isWhiteLabel={config.isWhitelabel} product={product} />;
};

export default ProductMedia;
