import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@goldbely/explore-component-library';
import cls from 'classnames';

import { SendAsEgiftModal } from 'src/shoppe/elements/SendAsEgiftModal';

import styles from './index.module.sass';

interface Props {
  id?: number;
  canAddToCart: boolean;
  displayPrice: number;
  live?: boolean;
  optionSoldOut: boolean;
  productId: number;
  purchaseButtonText: string;
  soldOutReason?: string;
  invalidProductOption: boolean;
  isEgiftable: boolean;
  isWhitelabel: boolean;
}

export const StickyAddToCart: React.FC<Props> = ({
  id,
  canAddToCart,
  displayPrice,
  live,
  optionSoldOut,
  productId,
  purchaseButtonText,
  invalidProductOption,
  isEgiftable,
  isWhitelabel,
}) => {
  const [ isVisible, setIsVisible ] = useState<boolean>( false );
  const [ open, setOpen ] = useState( false );
  const cartVisible = useSelector<any, any>(( state ) => state?.Cart?.visible );

  const handleOpen = ( event: React.FormEvent ) => {
    event.preventDefault();
    setOpen( true );
  };

  const handleClose = () => {
    setOpen( false );
  };

  const addToCartTitle = useMemo(() => {
    if (( canAddToCart || isWhitelabel || live ) && !invalidProductOption && !optionSoldOut ) {
      return purchaseButtonText;
    }
    if ( invalidProductOption ) {
      return 'Unavailable';
    }
    return 'Add to Cart';
  }, [ canAddToCart, isWhitelabel, live, invalidProductOption, optionSoldOut, purchaseButtonText ]);

  useEffect(() => {
    const checkIfScrolledPast = () => {
      const elementTarget = document.getElementById( 'add-to-cart-area' );
      if (
        elementTarget?.getBoundingClientRect().y < 0 ||
        elementTarget?.getBoundingClientRect().y > window.innerHeight
      ) {
        setIsVisible( true );
      } else {
        setIsVisible( false );
      }
    };
    checkIfScrolledPast();

    window.addEventListener( 'scroll', checkIfScrolledPast );

    return () => {
      window.removeEventListener( 'scroll', checkIfScrolledPast );
    };
  }, []);

  if ( !canAddToCart || isWhitelabel || optionSoldOut ) return null;

  return (
    <>
      <span id="add-to-cart-area" />
      {isVisible && !cartVisible && (
        <div className={cls( styles.container, 'spec__sticky-add-to-cart' )}>
          {isEgiftable && (
            <Button
              className={cls( styles.buttonEgift, 'spec__sticky-add-to-cart__egift' )}
              disabled={!canAddToCart}
              data-cart-item-id={id}
              data-product-display-price={displayPrice}
              data-product-id={productId}
              data-submit="false"
              onClick={handleOpen}
              variant="egift"
              name="egift_submit"
              outline
              type="submit"
            >
              Send as E-Gift
            </Button>
          )}

          <Button
            className={cls(
              styles.buttonAddToCart,
              'js__add-to-cart',
              'spec__sticky-add-to-cart__add'
            )}
            disabled={!canAddToCart}
            data-cart-item-id={id}
            data-product-display-price={displayPrice}
            data-product-id={productId}
            data-submit="false"
            type="submit"
          >
            {addToCartTitle}
          </Button>
        </div>
      )}

      <SendAsEgiftModal open={open} handleClose={handleClose} />
    </>
  );
};
