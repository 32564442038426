import React, { FC } from 'react';

import { NavToggle } from 'src/shoppe/elements/NavToggle';

import styles from './index.module.sass';

interface MobileMenuToggleProps {
  isActive: boolean;
  onClick: () => void;
}

const MobileMenuToggle: FC<MobileMenuToggleProps> = ({ isActive, onClick }) => (
  <NavToggle className={{ container: styles.button }} isActive={isActive} onClick={onClick} />
);

export default MobileMenuToggle;
