import React, { FC } from 'react';

import { Header } from './elements/Header';
import { Links } from './elements/Links';
import { Products } from './elements/Products';
import { Video } from './elements/Video';

import styles from './index.module.sass';

const TheMastersLanding: FC = () => (
  <div className={styles.container}>
    <div>
      <Header />
      <Products />
    </div>
    <Video />
    <Links />
  </div>
);

export default TheMastersLanding;
