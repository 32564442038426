import * as React from 'react';

import styles from './index.module.sass';

interface Props {
  defaultValue?: string | number;
  disabled?: boolean;
  id?: string;
  name?: string;
  onChange?: ( event: React.ChangeEvent<HTMLSelectElement> ) => void;
}

export const Select: React.FC<Props> = ({
  children,
  defaultValue,
  disabled,
  id,
  name,
  onChange,
}) => (
  <select
    className={`${styles.select} form-control pl-4 rounded fs-5`}
    defaultValue={defaultValue}
    disabled={disabled}
    id={id}
    name={name}
    onChange={onChange}
  >
    {children}
  </select>
);
