import React, { useState } from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';

import { useShopConfig } from 'src/explore/hooks/useShopConfig';
import { NotificationForm } from 'src/shoppe/elements/NotificationForm';

import styles from './index.module.sass';

interface Props {
  id?: number;
  canAddToCart: boolean;
  displayPrice: number;
  isEgiftable?: boolean;
  isFlashSale: boolean;
  live?: boolean;
  optionSoldOut: boolean;
  productId: number;
  purchaseButtonText: string;
  soldOutReason: string;
  invalidProductOption: boolean;
}

export const Submit: React.FC<Props> = ({
  id,
  canAddToCart,
  displayPrice,
  isEgiftable,
  isFlashSale,
  live,
  optionSoldOut,
  productId,
  purchaseButtonText,
  soldOutReason,
  invalidProductOption,
}) => {
  const config = useShopConfig();
  const isTheMasters = config.merchantPermalink === 'taste-of-the-masters';
  const waitlistText = isTheMasters ? 'Notify Me About Future Offers' : 'Join the Waitlist'
  const soldOutText = soldOutReason === 'Coming Soon' ? 'Notify Me' : waitlistText;

  const [ notifyModal, setNotifyModal ] = useState<boolean>( false );

  const addToCartBtn = () => {
    if (( canAddToCart || live ) && !invalidProductOption && !optionSoldOut ) {
      return (
        <button
          className={`${styles.button} product__submit btn btn-block btn-secondary py-2 fs-7 js__add-to-cart spec__add-to-cart`}
          disabled={!canAddToCart}
          data-cart-item-id={id}
          data-product-display-price={displayPrice}
          data-product-id={productId}
          data-submit="false"
          type="submit"
        >
          {purchaseButtonText}
        </button>
      );
    }

    if ( invalidProductOption ) {
      return (
        <button
          className={`${styles.button} product__submit btn btn-block btn-secondary py-2 fs-7 spec__invalid-product-option`}
          type="button"
          disabled
        >
          Unavailable
        </button>
      );
    }

    if ( optionSoldOut || isFlashSale ) {
      return (
        <button
          className={`${styles.button} product__submit btn btn-block btn-secondary py-2 fs-7 spec__option-sold-out`}
          type="button"
          disabled
        >
          Sold Out
        </button>
      );
    }

    return (
      <button
        className={`${styles.button} product__submit btn btn-block btn-secondary d-flex justify-content-center align-items-center py-2 fs-7`}
        type="button"
        onClick={() => setNotifyModal( true )}
      >
        <FontAwesomeIcon className="mr-2 fs-8" icon={faEnvelope} />
        {soldOutText}
      </button>
    );
  };

  return (
    <>
      <div
        className={cls( 'd-flex spec__product__add-to-cart-button', {
          'px-4': !config.isWhitelabel && isEgiftable,
          [styles.white]: config.isWhitelabel,
        })}
      >
        {addToCartBtn()}
      </div>

      <NotificationForm
        open={notifyModal}
        productID={productId}
        onClose={() => setNotifyModal( false )}
        showSubscribeCheckbox={!config.isWhitelabel}
      />
    </>
  );
};
