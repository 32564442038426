import React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface Props {
  activeIndex?: number;
  classNames?: {
    container?: string;
    indicator?: string;
    selected?: string;
  };
  length: number;
  onClick: ( indicator: number ) => void;
}

export const CarouselIndicators = ({ activeIndex, classNames = {}, length, onClick }: Props ) =>
  length <= 1 ? (
    <></>
  ) : (
    <ul className={cls( classNames.container, `text-center` )}>
      {
        // @ts-ignore
        [ ...Array( length ).keys() ].map(( item, i ) => (
          <li key={item} className="d-inline-block">
            <button
              aria-label={`View #${i}`}
              className={cls( styles.indicator, classNames.indicator, 'p-0 mx-2', {
                [styles.selected]: activeIndex === i,
                [classNames.selected]: activeIndex === i,
                [styles.interactive]: onClick,
              })}
              type="button"
              onClick={() => onClick( i )}
            />
          </li>
        ))
      }
    </ul>
  );
