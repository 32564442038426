import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cls from 'classnames';

import { Recombee } from 'src/explore/services/recommendations/Recombee';
import SvgIcon from 'src/explore/elements/SvgIcon';
import useFavorite from 'src/explore/hooks/useFavorite';
import { IStore } from 'src/explore/types/store';

import styles from './index.module.sass';

interface Props {
  productId: number;
}

const Favorite = ({ productId }: Props ) => {
  const searchParams = new URLSearchParams( window.location.search );
  const recommId = searchParams.get( 'recomm_id' );

  const account = useSelector(( state: IStore ) => state.data.account );
  /* eslint-disable-next-line camelcase */
  const identity = account.data?.hashed_id || account.meta?.visitor_id;

  const [ isFavorite, toggleFavorite ] = useFavorite( productId, recommId );

  useEffect(() => {
    if ( account.pristine || !identity ) return;

    new Recombee().sendItemView( identity, productId, { recommId });
  }, [ account, identity, productId, recommId ]);

  const onToggleFavorite = ( e: React.MouseEvent ) => {
    e.preventDefault();
    toggleFavorite();
  };

  return (
    <button
      type="button"
      onClick={onToggleFavorite}
      className={cls( styles.favorite, 'border-0 favorites-link position-absolute fs-8', {
        [`${styles.favoriteOn} spec__heart-icon__favorite`]: isFavorite,
      })}
    >
      {isFavorite ? (
        <SvgIcon
          icon="icon-heart-filled"
          iconClasses={styles.favoriteOn}
          svgClasses={cls( styles.icon, 'align-items-center' )}
        />
      ) : (
        <SvgIcon
          icon="icon-heart-thin"
          iconClasses={styles.graphic}
          svgClasses={cls( styles.icon, 'align-items-center' )}
        />
      )}
    </button>
  );
};

export default Favorite;
