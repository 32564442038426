export const getSaleText = ( date?: string ) => {
  const defaultText = 'Sale';

  if ( date === undefined ) {
    return defaultText;
  }

  const saleEndsDate = new Date( date );
  const saleEndsIsDate = saleEndsDate instanceof Date && !Number.isNaN( saleEndsDate.valueOf())
  if ( !saleEndsIsDate ) {
    return defaultText;
  }

  const now = new Date();
  const timeLeft = saleEndsDate.valueOf() - now.valueOf();
  const saleHasEnded = timeLeft < 0;
  if ( saleHasEnded ) {
    return defaultText;
  }
  
  const twentyFourHours = 86400000;
  const moreThanThreeDays = saleEndsDate.valueOf() > now.valueOf() + ( twentyFourHours * 3 );
  if ( moreThanThreeDays ) {
    return defaultText; 
  }

  const moreThanTwoDays = saleEndsDate.valueOf() > now.valueOf() + ( twentyFourHours * 2 );
  if ( moreThanTwoDays ) {
    return 'Sale Ends Soon!'; 
  }

  const moreThanOneDay = saleEndsDate.valueOf() > now.valueOf() + twentyFourHours;
  if ( moreThanOneDay ) {
    return 'Sale Ends Tomorrow!';
  }

  const oneHour = 3600000;
  const hours = Math.floor( timeLeft / oneHour );
  const minutes = Math.floor(( timeLeft % oneHour ) / 60000 );

  if ( hours === 0 && minutes === 0 ) {
    return undefined;
  }
  
  return `Sale Ends in ${ hours > 0 ? `${hours} hr and ` : '' }${minutes} min`;
};
