import React, { FC } from 'react';
import cls from 'classnames';

import { DollarsAndCents } from 'src/explore/elements/DollarsAndCents';
import { IPromotionProduct } from 'src/explore/types/shoppe';

import { SalePillBadge } from '../SalePillBadge';

import styles from './index.module.sass';

interface Props {
  displayDiscountPricing: boolean;
  effectivePriceInCents: number;
  flashSale?: IPromotionProduct;
  freeShipping: boolean;
  priceInCents: number;
  priceSubheading?: string;
  saleEndsAt?: string;
}

export const Price: FC<Props> = ({
  displayDiscountPricing,
  effectivePriceInCents,
  flashSale,
  freeShipping,
  priceInCents,
  priceSubheading,
  saleEndsAt,
}) => (
  <div className="product__price-wrap">
    {flashSale ? (
      <>
        <strong
          className={`${styles.flashSale} fs-8 fs-lg-10 ff-futura-bold spec__flash-sale-price`}
          itemProp="price"
        >
          <DollarsAndCents
            amountInCents={flashSale.afterPromotionProductPriceInCents}
            className={{ cents: `${styles.super} position-relative fs-5 fs-lg-8` }}
          />
        </strong>
      </>
    ) : (
      <>
        {displayDiscountPricing && priceInCents > effectivePriceInCents && (
          <div className={`${styles.lineThrough} fs-5 spec__product-original-price`}>
            <DollarsAndCents amountInCents={priceInCents} />
          </div>
        )}

        <strong
          className="fs-8 fs-lg-10 ff-futura-bold text-dark spec__product-price"
          itemProp="price"
        >
          <DollarsAndCents
            amountInCents={effectivePriceInCents}
            className={{ cents: `${styles.super} position-relative fs-5 fs-lg-8` }}
          />
        </strong>
      </>
    )}

    {priceSubheading && <h4 className={`${styles.subheading} mt-md-n1 fs-3`}>{priceSubheading}</h4>}

    {freeShipping && (
      <div
        className={cls( 'fs-3 text-uppercase ff-futura-bold font-weight-bold text-dark ', {
          'mt-md-n1': !priceSubheading,
        })}
      >
        + Free Shipping
      </div>
    )}
    {displayDiscountPricing && <SalePillBadge saleEndsAt={saleEndsAt} />}
  </div>
);
