import React from 'react';

import { IProduct } from 'src/explore/types/shoppe';
import SvgIcon from 'src/explore/elements/SvgIcon';
import { useRoutes } from 'src/explore/hooks/useRoutes';

import { Detail } from './elements/Detail';

interface DetailsProps {
  product: Pick<
    IProduct,
    | 'availableForPickUp'
    | 'customerInstructionProductUrl'
    | 'moreDetailsHtml'
    | 'productIngredientsHtml'
    | 'shippingDetailsHtml'
    | 'useAndCareHtml'
  >;
}

export const Details = ({ product }: DetailsProps ) => {
  const {
    availableForPickUp,
    customerInstructionProductUrl,
    moreDetailsHtml,
    productIngredientsHtml,
    shippingDetailsHtml,
    useAndCareHtml,
  } = product;

  const routes = useRoutes();
  const isPikePlaceFish = new RegExp( 'pikeplacefish.goldbelly' ).test( window.location.hostname );
  const isTasteOfTheMasters = new RegExp( 'tasteofthemasters.goldbelly' ).test(
    window.location.hostname
  );

  return (
    <>
      {moreDetailsHtml && (
        <Detail title="More Details">
          <div dangerouslySetInnerHTML={{ __html: moreDetailsHtml }} />
        </Detail>
      )}

      {productIngredientsHtml && (
        <Detail title="Ingredients">
          <div dangerouslySetInnerHTML={{ __html: productIngredientsHtml }} />
        </Detail>
      )}

      {useAndCareHtml && (
        <Detail title="Instructions / Storage">
          <div dangerouslySetInnerHTML={{ __html: useAndCareHtml }} />
          {customerInstructionProductUrl && (
            <a
              className="mb-5 px-2 d-inline-block fs-4 d-inline-flex align-items-center"
              rel="noreferrer"
              target="_blank"
              href={customerInstructionProductUrl}
            >
              <SvgIcon icon="icon-instructions" svgClasses="mr-1 fs-8" />
              Download Storage and Prep Instructions
            </a>
          )}
        </Detail>
      )}

      <Detail title={availableForPickUp ? 'Pick Up Details' : 'Shipping Details'}>
        {shippingDetailsHtml && <div dangerouslySetInnerHTML={{ __html: shippingDetailsHtml }} />}
        {!availableForPickUp && !isPikePlaceFish && !isTasteOfTheMasters && (
          <ul>
            <li>
              {'Have more questions about Shipping? Read our '}
              <a href={routes.help.shippingFAQ()}>Shipping FAQ</a>
              {' page.'}
            </li>
          </ul>
        )}
      </Detail>
    </>
  );
};
