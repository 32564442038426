import React, { FC } from 'react';

import styles from './index.module.sass';

export const Header: FC = () => (
  <div className={styles.description}>
    Elevate your watch party with Taste of the Masters, delivering Augusta National Golf
    Club&apos;s timeless concessions to your door. Order now, so you can enjoy and serve
    your guests the ultimate Tournament viewing experience for the 2025 Masters, April 10-13.
  </div>
);
