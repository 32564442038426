import React from 'react';

import { SvgUse } from 'src/shoppe/elements/SvgUse';

import styles from './index.module.sass';

export const OnSale = () => (
  <SvgUse
    ariaLabel="Sale"
    className={`${styles.onSale} position-absolute spec__on-sale-badge`}
    name="sale-badge"
  />
);
