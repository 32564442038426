import React, { useState } from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

interface FixedProps {
  children?: React.ReactNode;
  onClick?: () => void;
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
}

export const Fixed = ({ children, onClick, onSwipeLeft, onSwipeRight }: FixedProps ) => {
  const [ swipeStart, setSwipeStart ] = useState({ x: 0, y: 0 });

  const handleTouchStart = ( e: React.TouchEvent<HTMLDivElement> ) => {
    setSwipeStart({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY,
    });
  };

  const handleTouchEnd = ( e: React.TouchEvent<HTMLDivElement> ) => {
    const deltaX = e.changedTouches[0].clientX - swipeStart.x;
    const deltaY = e.changedTouches[0].clientY - swipeStart.y;

    // if scrolling vertically
    if ( Math.abs( deltaY ) * 0.8 >= Math.abs( deltaX )) return;

    if ( deltaX > 0 ) {
      onSwipeRight();
    } else if ( deltaX < 0 ) {
      onSwipeLeft();
    }
  };

  return (
    <div
      className={cls( styles.fixed, 'position-absolute', {
        [styles.withClick]: onClick,
      })}
      onClick={onClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </div>
  );
};
