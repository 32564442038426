import React, { FC } from 'react';
import cls from 'classnames';

import { IWebLink } from '../../types';
import Link from './atoms/Link';

import styles from './index.module.sass';

interface LinksProps {
  data?: IWebLink[];
  overlay: boolean;
}

const Links: FC<LinksProps> = ({ data, overlay }) => {
  if ( !data || data.length < 1 ) return null;

  return (
    <ul
      className={cls( styles.container, {
        [styles.overlay]: overlay,
      })}
    >
      {data.map(( item ) => (
        <li key={item.id}>
          <Link href={item.url} visible={overlay}>
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Links;
