import * as React from 'react';
import cls from 'classnames';

import { Select } from '../Select';

import styles from './index.module.sass';

interface Props {
  className?: string;
  disabled: boolean;
  effectiveQuantityLabel: string;
  possibleQuantities: Array<{
    label: string;
    value: number;
  }>;
  quantity: number;
  handleChange: ( newQuantity: number ) => void;
}

export const Quantity: React.FC<Props> = ({
  className,
  disabled,
  effectiveQuantityLabel,
  possibleQuantities,
  quantity,
  handleChange = () => {},
}) => (
  <label
    className={cls(
      'd-flex flex-shrink-0 align-items-center p-0 mb-auto font-weight-normal spec__quantity',
      {
        [styles.disabled]: disabled,
      },
      className
    )}
    htmlFor="cart_item_quantity"
  >
    <span className={`${styles.label} product--quantity--label mr-2`}>
      {effectiveQuantityLabel}
    </span>
    <Select
      defaultValue={quantity}
      disabled={disabled}
      id="cart_item_quantity"
      name="cart_item[quantity]"
      onChange={( event: React.ChangeEvent<HTMLSelectElement> ) =>
        handleChange( Number( event.currentTarget.value ?? 1 ))
      }
    >
      {possibleQuantities.map(( possibleQuantity ) => (
        <option key={possibleQuantity.value} value={possibleQuantity.value}>
          {possibleQuantity.label}
        </option>
      ))}
    </Select>
  </label>
);
