import React, { FC }  from 'react';

import { LazyImgix } from '@goldbely/explore-component-library';

import styles from './index.module.sass';

type ILinkProps = {
  description: string;
  href: string;
  src: string;
  title: string;
};

export const Link: FC<ILinkProps> = ({ description, href, src, title }) => (
  <a className={styles.link} href={href}>
    <LazyImgix className={styles.image} src={src} />
    <div className={styles.info}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.description}>{description}</div>
    </div>
  </a>
);
