import * as React from 'react';
import cls from 'classnames';

import { JWCustomElem } from 'src/shoppe/elements/JWCustomElem';
import { JWPlayer } from 'src/shoppe/elements/JWPlayer';
import { LazyImgix } from 'src/explore/elements/LazyImgix';
import { ProductMediaDetails } from 'src/shoppe/elements/ProductMediaDetails';
import { IProduct } from 'src/explore/types/shoppe';
import { useShopConfig } from 'src/explore/hooks/useShopConfig';

import styles from './index.module.sass';

interface Props {
  className?: string;
  hideSoldOut?: boolean;
  imageUrl: string;
  imageAlt?: string;
  playerId: string;
  product?: IProduct;
  sizes?: string;
  videoFullUrl?: string;
  videoProps?: IVideoProps;
}

export interface IVideoProps {
  aspectRatio?: 'inherit' | '4:5' | '16:9';
  playlist?: string;
  controls?: boolean;
  repeat?: boolean;
  stretching?: 'exactfit' | 'fill' | 'none' | 'uniform';
}

export const MediaItem = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      hideSoldOut,
      imageUrl,
      imageAlt,
      playerId,
      product,
      sizes,
      videoFullUrl,
      videoProps = {},
    },
    ref
  ) => {
    const [ playing, setPlaying ] = React.useState( false );

    const config = useShopConfig();

    return (
      <div
        className={cls(
          'h-100 w-100 position-relative d-flex align-items-center justify-content-center spec__media-item',
          className
        )}
        ref={ref}
      >
        {videoFullUrl || videoProps.playlist ? (
          <JWPlayer
            aspectRatio={videoProps.aspectRatio || '1:1'}
            className="h-100 w-100"
            customProps={videoProps}
            image={imageUrl}
            isMuted
            file={videoFullUrl}
            playlist={videoProps.playlist}
            playerId={playerId}
            onAutoStart={() => setPlaying( true )}
            onResume={() => setPlaying( true )}
            onPlay={() => setPlaying( true )}
            onOneHundredPercent={() => setPlaying( false )}
            onPause={() => setPlaying( false )}
          >
            {!videoProps.controls && (
              <JWCustomElem id="play-overlay">
                <div
                  className={cls( styles.playIcon, 'position-absolute', {
                    [styles.visible]: !playing,
                  })}
                />
              </JWCustomElem>
            )}
          </JWPlayer>
        ) : (
          imageUrl && (
            <LazyImgix
              className={cls( styles.image, 'mh-100 mw-100' )}
              imgixParams={{ ar: '1:1' }}
              alt={imageAlt}
              itemProp="image"
              sizes={sizes || '100vw'}
              src={imageUrl}
            />
          )
        )}

        {product && (
          <ProductMediaDetails
            product={product}
            isWhitelabel={config.isWhitelabel}
            hideSoldOut={hideSoldOut}
            showFavorite
          />
        )}
      </div>
    );
  }
);
