import React from 'react';
import cls from 'classnames';

import { SvgUse } from 'src/shoppe/elements/SvgUse';

import styles from './index.module.sass';

type Props = {
  icon: string;
  title: string;
};

export const Step = ({ icon, title }: Props ) => (
  <div className={cls( 'col-md-4 d-flex', styles.container )}>
    <div className={styles.iconContainer}>
      <SvgUse className={styles.svg} ariaHidden="true" name={icon} height={32} width={32} />
    </div>
    <p className={styles.stepDescription}>{title}</p>
  </div>
);
