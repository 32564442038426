import React, { FC } from 'react';
import { Grid, Column } from '@goldbely/explore-component-library';

import { Routes } from 'src/explore/services/Routes';

import { Link } from './elements/Link';

import styles from './index.module.sass';

export const Links: FC = () => (
  <div className={styles.container}>
    <h1 className={styles.title}>Plan Your Watch Party</h1>
    <Grid className={styles.grid}>
      <Column span={{ lg: 6 }}>
        <Link
          href="https://www.masters.com/en_US/bring-your-masters-best"
          src={Routes.image.consumer( 'wl/tasteofthemasters/bring-your-masters-best.png' )}
          title="Bring Your Masters Best"
          description="Spring is in the air. Celebrate with your best Masters party this April."
        />
      </Column>
      <Column span={{ lg: 6 }}>
        <Link
          href="https://www.pinterest.com/masterstournament"
          src={Routes.image.consumer( 'wl/tasteofthemasters/gather-tournament-inspiration.jpg' )}
          title="Gather Tournament Inspiration"
          description="Visit our Pinterest page for inspiration to perfect your Masters set-up and watch in style."
        />
      </Column>
    </Grid>
  </div>
);
