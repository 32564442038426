import React from 'react';

import { IAvailableOption, IAvailableVariance, IProduct } from 'src/explore/types/shoppe';

import { GetItBy } from './compositions/GetItBy';
import { SpecialDateWarning } from './elements/SpecialDateWarning';

interface ProductDeliveryInfoProps {
  product: IProduct;
  isWhiteLabel: boolean;
  choices?: IAvailableOption[];
  options?: IAvailableVariance[];
  quantity?: number;
}

export const ProductDeliveryInfo = ({
  product,
  isWhiteLabel,
  choices = [],
  options = [],
  quantity = 1,
}: ProductDeliveryInfoProps ) => {
  const {
    acceptsRequestedDeliveryDate,
    canAddToCart,
    numberOfShippingDays,
    hasMultipleSpeeds,
    id,
  } = product;

  return (
    <>
      <SpecialDateWarning product={product} isWhiteLabel={isWhiteLabel} />

      <GetItBy
        acceptsRequests={acceptsRequestedDeliveryDate}
        canUpgrade={hasMultipleSpeeds}
        isAvailable={canAddToCart}
        maxDays={numberOfShippingDays}
        productId={id}
        choices={choices}
        options={options}
        quantity={quantity}
        withMoreDates
      />
    </>
  );
};
