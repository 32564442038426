import React from 'react';

import { ScrollLink } from 'src/explore/elements/ScrollLink';
import { IMerchant } from 'src/explore/types/shoppe';
import { useRoutes } from 'src/explore/hooks/useRoutes';

import { Rating } from './elements/Rating';

import styles from './index.module.sass';

interface MerchantProps {
  data: Pick<IMerchant, 'customerRating' | 'name' | 'permalink' | 'publishedReviews'>;
}

export const Merchant = ({
  data: { customerRating, name, permalink, publishedReviews },
}: MerchantProps ) => {
  const rating = Number( customerRating );

  const routes = useRoutes();

  return (
    <div className="mt-1">
      {'From '}
      <a
        className="text-secondary text-decoration-none spec__product-merchant"
        href={routes.merchant( permalink )}
        id={permalink}
        itemProp="brand"
      >
        {name}
      </a>

      {publishedReviews.length > 0 ? (
        <ScrollLink
          className={`${styles.rating} d-inline-block order-2 mb-1 ml-1 font-weight-bold fs-4 text-decoration-none spec__merchant-rating`}
          target="#merchant-reviews"
          targetOffset="header--explore"
        >
          ({rating > 0 ? <Rating value={rating} /> : 'Customer Reviews'})
        </ScrollLink>
      ) : (
        rating > 0 && (
          <span
            className={`${styles.rating} d-inline-block order-2 mb-1 ml-1 text-dark font-weight-bold fs-4 spec__merchant-rating`}
          >
            (<Rating value={rating} />)
          </span>
        )
      )}
    </div>
  );
};
