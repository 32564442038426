import React from 'react';

import { IMerchant } from 'src/explore/types/shoppe';

import { Merchant } from './elements/Merchant';
import { NewBadge } from './elements/NewBadge';

interface ProductHeaderInlineV1Props {
  isNew?: boolean;
  isWhiteLabel: boolean;
  merchant?: IMerchant;
  name: string;
}

export const ProductHeaderInlineV1 = ({
  isNew,
  isWhiteLabel,
  merchant,
  name,
}: ProductHeaderInlineV1Props ) => (
  <>
    {isNew && <NewBadge />}

    <h1 className="fs-7 fs-md-8 spec__product__name" itemProp="name">
      {name}
    </h1>

    {!isWhiteLabel && merchant && <Merchant data={merchant} />}
  </>
);
