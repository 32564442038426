import React, { useState } from 'react';
import cls from 'classnames';

import { IProduct } from 'src/explore/types/shoppe';
import {
  ANIMATION_DURATION,
  useMediaShowcaseHandlers,
  MediaShowcase,
} from 'src/shoppe/elements/MediaShowcase';
import { ProductMediaDetails } from 'src/shoppe/elements/ProductMediaDetails';
import productImageAltText from 'src/explore/services/productImageAltText';

import { Thumbnails } from './elements/Thumbnails';

import styles from './index.module.sass';

interface ProductMediaProps {
  isWhiteLabel: boolean;
  product: IProduct;
}

export const ProductMedia = ({ isWhiteLabel, product }: ProductMediaProps ) => {
  const { effectiveName, mediaAssets } = product;

  const modifiedAssets = mediaAssets.map(( v, k ) => ({
    ...v,
    imageAlt: v.imageAlt || productImageAltText( product, k ),
  }));

  // prettier-ignore
  const {
    animating,
    focus,
    previousFocus,
    onNext,
    onPrevious,
    onSelect,
  } = useMediaShowcaseHandlers({ media: mediaAssets });

  const [ scrollToList, setScrollToList ] = useState( 1 );

  const handleNext = () => {
    if ( !animating ) {
      setScrollToList( focus + 1 === mediaAssets.length ? 2 : 1 );
      onNext();
    }
  };
  const handlePrevious = () => {
    if ( !animating ) {
      setScrollToList( focus - 1 < 0 ? 0 : 1 );
      onPrevious();
    }
  };
  const handleSelect = ({ group, selected }: { group: number; selected: number }) => {
    if ( !animating ) {
      setScrollToList( group );
      onSelect({ group, selected });
    }
  };

  return (
    <div className="px-sm-4">
      <div
        className={cls( styles.container, 'position-relative', {
          [styles.containerMultiple]: mediaAssets.length > 1,
        })}
      >
        <MediaShowcase
          className={cls({
            [styles.showcaseMultiple]: mediaAssets.length > 1,
          })}
          focus={focus}
          media={modifiedAssets}
          name={effectiveName}
          previousFocus={previousFocus}
          showSelect
          onNext={handleNext}
          onPrevious={handlePrevious}
          onSelect={handleSelect}
        >
          <ProductMediaDetails product={product} isWhitelabel={isWhiteLabel} showFavorite />
        </MediaShowcase>

        {mediaAssets.length > 1 && (
          <Thumbnails
            animationDuration={ANIMATION_DURATION}
            focus={focus}
            media={modifiedAssets}
            previousFocus={previousFocus}
            scrollToList={scrollToList}
            onSelect={handleSelect}
          />
        )}
      </div>
    </div>
  );
};
