import React from 'react';

import { RatingStars } from 'src/explore/elements/RatingStars';

import styles from './index.module.sass';

interface RatingProps {
  value: number;
}

export const Rating = ({ value }: RatingProps ) => (
  <>
    <RatingStars className={`${styles.star} text-secondary fs-3`} value={value} />
    {` ${value}`}
  </>
);
