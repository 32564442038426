export const makeShippingDateEndpointUrl = ({
  baseUrl,
  quantity = 1,
  choices = [],
}: {
  baseUrl: string;
  quantity: number;
  choices: number[];
}) => {
  const params = new URLSearchParams();
  params.append( 'quantity', String( quantity ));
  if ( choices.length > 0 ) {
    choices.forEach(( choice ) => {
      params.append( 'product_option_ids[]', String( choice ));
    });
  }
  return `${baseUrl}?${params.toString()}`;
};
