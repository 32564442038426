import React, { FC } from 'react';

import { DollarsAndCents } from 'src/explore/elements/DollarsAndCents';
import { IPromotionProduct } from 'src/explore/types/shoppe';

import styles from './index.module.sass';

interface Props {
  effectivePriceInCents: number;
  flashSale?: IPromotionProduct;
}

export const FlashSaleDetails: FC<Props> = ({ effectivePriceInCents, flashSale }) => (
  <div className={`${styles.container} mt-1 mb-2 mb-md-4 spec__flash-sale-details`}>
    Reg. <DollarsAndCents amountInCents={effectivePriceInCents} />
    {' Use Promo Code: '}
    <strong className="text-uppercase">{flashSale.promotion.discountCode}</strong>
    {' for '}
    <strong>{flashSale.promotion.discountInPercent}% off</strong>
  </div>
);
