import React from 'react';

import hasSoldOutMessage from 'src/shoppe/services/hasSoldOutMessage';
import { IProduct } from 'src/explore/types/shoppe';
import { StatusOverlay } from 'src/explore/elements/StatusOverlay';
import { useShopConfig } from 'src/explore/hooks/useShopConfig';

import Favorite from './elements/Favorite';
import { OnSale } from './elements/OnSale';
import { ServingSize } from './elements/ServingSize';

interface ProductMediaDetailsProps {
  hideSoldOut?: boolean;
  isWhitelabel: boolean;
  product: Pick<
    IProduct,
    | 'canAddToCart'
    | 'displayDiscountPricing'
    | 'displayServingLabel'
    | 'displayServingSize'
    | 'displayServingUnit'
    | 'editorPick'
    | 'isEgiftCard'
    | 'id'
    | 'liveClassId'
    | 'soldOutReason'
  >;
  showFavorite?: boolean;
}

export const ProductMediaDetails = ({
  hideSoldOut,
  isWhitelabel,
  product: {
    canAddToCart,
    displayDiscountPricing,
    displayServingLabel,
    displayServingSize,
    displayServingUnit,
    isEgiftCard,
    id,
    liveClassId,
    soldOutReason,
  },
  showFavorite,
}: ProductMediaDetailsProps ) => {
  const config = useShopConfig();

  return (
    <>
      {isWhitelabel &&
        !canAddToCart &&
        !( hideSoldOut && hasSoldOutMessage( soldOutReason, isWhitelabel, !!liveClassId )) && (
          <StatusOverlay
            className="fs-10 spec__sold-out-overlay"
            text={
              hasSoldOutMessage( soldOutReason, isWhitelabel, !!liveClassId )
                ? config.ui?.sold_out_label || 'Sold Out'
                : soldOutReason
            }
          />
        )}

      {displayServingSize && (
        <ServingSize
          displayServingLabel={displayServingLabel}
          displayServingSize={displayServingSize}
          displayServingUnit={displayServingUnit}
        />
      )}

      {displayDiscountPricing && <OnSale />}

      {!isWhitelabel && !isEgiftCard && <>{showFavorite && <Favorite productId={id} />}</>}
    </>
  );
};
