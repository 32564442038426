import * as React from 'react';

import Breadcrumbs from './elements/Breadcrumbs';
import BreadcrumbItem from './elements/BreadcrumbItem';

import styles from './index.module.sass';

interface Breadcrumb {
  name: string;
  url: string;
}

interface Props {
  breadcrumbs: Breadcrumb[];
}

const BreadcrumbsScene: React.FC<Props> = ({ breadcrumbs }) => {
  if ( !breadcrumbs ) return null;
  return (
    <Breadcrumbs className={styles.container}>
      {breadcrumbs.map(({ name, url }, index ) => (
        <BreadcrumbItem key={name} title={name} url={url} position={index + 1} />
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsScene;
