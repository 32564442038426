import React from 'react';
import cls from 'classnames';

import {
  IAvailableOption,
  IAvailableVariance,
  INextShippingOption,
} from 'src/explore/types/shoppe';
import { useApiObject } from 'src/explore/hooks/useApiObject';

import { Shipping } from './elements/Shipping';
import { makeShippingDateEndpointUrl } from './services/endpointHelper';

interface GetItByProps {
  acceptsRequests: boolean;
  canUpgrade: boolean;
  className?: {
    container?: string;
    date?: string;
  };
  isAvailable: boolean;
  maxDays: number;
  productId: number;
  withMoreDates?: boolean;
  choices?: IAvailableOption[];
  options?: IAvailableVariance[];
  quantity?: number;
}

export const GetItBy = ({
  acceptsRequests,
  className = {},
  isAvailable,
  maxDays,
  productId,
  withMoreDates = false,
  choices = [],
  options = [],
  quantity = 1,
}: GetItByProps ) => {
  const nextShippingOptionEndpoint = makeShippingDateEndpointUrl({
    baseUrl: `products/${productId}/next_shipping_option`,
    quantity,
    choices: choices.map(( choice: IAvailableOption ) => choice.gbFormFieldValue ),
  });
  const nextShippingOption = useApiObject<INextShippingOption>( nextShippingOptionEndpoint, {
    camelCaseKeys: true,
  });

  if ( !isAvailable || !nextShippingOption.data ) {
    return null;
  }

  return (
    <div
      className={cls(
        'product__delivery-options pt-4 mt-4 mx-auto fs-5 text-center spec__delivery-options',
        className.container
      )}
    >
      <Shipping
        acceptsRequests={acceptsRequests}
        className={className}
        maxDays={maxDays}
        nextShippingOption={nextShippingOption.data}
        productId={productId}
        withMoreDates={withMoreDates}
        choices={choices}
        options={options}
        quantity={quantity}
      />
    </div>
  );
};
