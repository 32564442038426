import React from 'react';
import cls from 'classnames';

import { Modal } from 'src/shoppe/elements/Modal';
import { SvgUse } from 'src/shoppe/elements/SvgUse';

import styles from './index.module.sass';
import { Step } from './elements/Step';

type SendAsEgiftModalProps = {
  open: boolean;
  handleClose: () => void;
};

export const SendAsEgiftModal = ({ open, handleClose }: SendAsEgiftModalProps ) => (
  <Modal
    closeBtn
    closeClass={styles.close}
    open={open}
    overlayClass={styles.overlay}
    containerClass={styles.modal}
    onClick={handleClose}
  >
    <div className={cls( styles.wrap, 'h-100 row flex-wrap flex-column flex-md-row' )}>
      <div className="col-md-8 d-flex order-1">
        <div className="d-flex mr-4 mt-1">
          <div className={cls( styles.circle, 'd-flex rounded-circle' )}>
            <SvgUse className="m-auto" ariaHidden="true" name="gifts" height={16} width={20} />
          </div>
        </div>
        <div>
          <h1 className={cls( styles.heading, 'text-uppercase' )}>Send as E-Gift</h1>
          <p>
            It&apos;s the easiest way to gift Goldbelly! No shipping address is needed &mdash; just
            their email!
          </p>
        </div>
      </div>
      <div className="col-12 mt-4 order-3">
        <hr className="border" />
      </div>
      <div className={cls( 'order-4', styles.steps )}>
        <Step icon="gifts-food" title="1. Select “Send as E-Gift”" />
        <Step icon="gifts-phone" title="2. We'll notify them with a beautiful email" />
        <Step icon="gifts-calendar" title="3. They enter their info & delivery date" />
      </div>
      <div className="col-md-4 d-flex mt-4 mt-md-0 order-md-2 order-5">
        <button
          name="egift_submit"
          className={cls(
            styles.button,
            'btn btn-secondary my-auto font-weight-bold spec__send-as-egift-modal'
          )}
          type="submit"
        >
          Yes, Send as E-Gift!
        </button>
      </div>
    </div>
  </Modal>
);
